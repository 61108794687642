import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import screenSizes from '../../constants/screenSizes';
import { useHistory } from 'react-router-dom';

const BannerWrapper = styled.div`
  position: relative;
  height: 52px;
  background-color: ${(props) => props.color || '#db3e3e'};
  width: 100%;
  overflow: hidden;
`;

const animate = keyframes`
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: -200%;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 16px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  animation: ${animate} 30s -30s linear infinite;
  white-space: nowrap;
  letter-spacing: 1px;
  font-family: 'Inter';
  line-height: 20px;
  animation-play-state: ${(props) => (props.isPaused ? 'paused' : 'running')};

  @media (min-width: ${screenSizes.medium.min}) {
    animation: ${animate} 60s -60s linear infinite;
    animation-play-state: ${(props) => (props.isPaused ? 'paused' : 'running')};
  }
`;

const Item = styled.a`
  margin-right: 170px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const InformationalBanner = ({ color = '#db3e3e' }) => {
  const [activations, setActivations] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const history = useHistory();

  useEffect(() => {
    axios
      .get('/activations_banner/')
      .then((response) => {
        setActivations(response?.data);
      })
      .catch((error) => {
        console.log(error);
        setActivations(
          Array.from({ length: 3 }, (_, index) => ({
            id: index,
            title: 'Lorem ipsum dolor sit amet',
            date: '2021-09-01',
            country: 'Italy',
          })),
        );
      });
  }, []);

  const handleClick = (event, url) => {
    event.preventDefault();
    history.push(url);
    window.location.reload();
  };

  return (
    <BannerWrapper
      color={color}
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <TextWrapper isPaused={isPaused}>
        {activations?.map((activation, index) => (
          <Item
            key={`item-${index}`}
            href={`/products?region=${encodeURIComponent(
              activation.country || activation.region,
            )}&title=`}
            onClick={(event) =>
              handleClick(
                event,
                `/products?region=${encodeURIComponent(
                  activation.country || activation.region,
                )}&title=`,
              )
            }
          >
            {Object.values(activation).join(' - ')}
          </Item>
        ))}
      </TextWrapper>
    </BannerWrapper>
  );
};

export default InformationalBanner;
