import React from 'react';
import styled from '@emotion/styled';
import { routes } from '../../constants/routes';
import { urls } from '../../constants/urls';
import { ReactComponent as TwitterLogo } from '../../assets/Twitter.svg';
import { ReactComponent as FacebookLogo } from '../../assets/Facebook.svg';
import { ReactComponent as LinkedinLogo } from '../../assets/LinkedIn.svg';
import { ReactComponent as YoutubeLogo } from '../../assets/Youtube.svg';
import { ReactComponent as UnitarUnosatLogo } from '../../assets/UNITAR_UNOSAT_Vertical_White.svg';
import screenSizes from '../../constants/screenSizes';

const FooterWrapper = styled.div`
  background: #788da0;
  color: #fff;
  width: 100%;
  padding: 20px 0;
`;

const DesktopFooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: ${screenSizes.medium.max}) {
    display: none;
  }
`;

const MobileFooterContent = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: ${screenSizes.medium.max}) {
    display: flex;
  }
`;

const LogoWrapper = styled.div`
  width: 103px;
`;

const CenterText = styled.div`
  text-align: center;
  white-space: nowrap;
  font-weight: 900;
  font-size: 15px;
  line-height: 26px;
`;

const TermsLink = styled.a`
  color: #fff;
  text-decoration: underline;
  margin-left: 10px;
  &:hover {
    text-decoration: underline;
    color: RGBA(255, 255, 255, 0.7);
  }
`;

const Social = styled.div`
  display: flex;
  gap: 15px;
`;

const SocialLink = styled.a`
  color: #fff;
  &:hover {
    opacity: 0.8;
  }
`;

const SvgX = styled.div`
  width: 24px;
  height: 24px;
`;

const MobileBottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;

const NewFooter = () => {
  return (
    <FooterWrapper>
      <DesktopFooterContent>
        <LogoWrapper>
          <a href={routes.HOME}>
            <UnitarUnosatLogo style={{ width: '100%', height: 'auto' }} />
          </a>
        </LogoWrapper>
        <CenterText>
          UNOSAT 2024
          <TermsLink href={urls.TERMS_OF_USE} target="_blank" rel="noreferrer">
            Terms of Use
          </TermsLink>
        </CenterText>
        <Social>
          <SocialLink href={urls.LINKEDIN} target="_blank" rel="noreferrer">
            <SvgX>
              <LinkedinLogo style={{ width: '100%', height: '100%' }} />
            </SvgX>
          </SocialLink>
          <SocialLink href={urls.TWITTER} target="_blank" rel="noreferrer">
            <SvgX>
              <TwitterLogo style={{ width: '100%', height: '100%' }} />
            </SvgX>
          </SocialLink>
          <SocialLink href={urls.FACEBOOK} target="_blank" rel="noreferrer">
            <SvgX>
              <FacebookLogo style={{ width: '100%', height: '100%' }} />
            </SvgX>
          </SocialLink>
          <SocialLink href={urls.YOUTUBE} target="_blank" rel="noreferrer">
            <SvgX>
              <YoutubeLogo style={{ width: '100%', height: '100%' }} />
            </SvgX>
          </SocialLink>
        </Social>
      </DesktopFooterContent>

      <MobileFooterContent>
        <CenterText>
          UNOSAT 2024
          <TermsLink href={urls.TERMS_OF_USE} target="_blank" rel="noreferrer">
            Terms of Use
          </TermsLink>
        </CenterText>
        <MobileBottomRow>
          <LogoWrapper>
            <a href={routes.HOME}>
              <UnitarUnosatLogo style={{ width: '100%', height: 'auto' }} />
            </a>
          </LogoWrapper>
          <Social>
            <SocialLink href={urls.LINKEDIN} target="_blank" rel="noreferrer">
              <SvgX>
                <TwitterLogo style={{ width: '100%', height: '100%' }} />
              </SvgX>
            </SocialLink>
            <SocialLink href={urls.TWITTER} target="_blank" rel="noreferrer">
              <SvgX>
                <TwitterLogo style={{ width: '100%', height: '100%' }} />
              </SvgX>
            </SocialLink>
            <SocialLink href={urls.FACEBOOK} target="_blank" rel="noreferrer">
              <FacebookLogo size={24} weight="fill" />
            </SocialLink>
            <SocialLink href={urls.YOUTUBE} target="_blank" rel="noreferrer">
              <YoutubeLogo size={24} weight="fill" />
            </SocialLink>
          </Social>
        </MobileBottomRow>
      </MobileFooterContent>
    </FooterWrapper>
  );
};

export default NewFooter;
