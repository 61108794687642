import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import Select, { createFilter } from 'react-select';
import { MagnifyingGlass } from 'phosphor-react';
import { format } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import screenSizes from '../../../constants/screenSizes';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom';

const commonInputStyles = `
  height: 35px;
  padding: 0 10px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-family: 'Inter';
  font-size: 13px;
  font-weight: 500;
  color: rgba(82, 94, 104, 1);
  border-radius: 30px;
  border: none;
  outline: none;
  width: fit-content;
`;

const HideOnSmallScreen = styled.div`
  @media (max-width: ${screenSizes.medium.max}) {
    display: none;
  }
`;

const Englobe = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 0;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${screenSizes.medium.min}) {
    display: none;
  }
`;

const SearchField = styled.div`
  ${commonInputStyles}
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  max-width: 300px;

  @media (min-width: ${screenSizes.medium.min}) {
    width: 15vw;
    min-width: 50px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    width: 15vw;
    min-width: 100px;
  }
`;

const SearchInput = styled.input`
  ${commonInputStyles}
  background-color: transparent;
  width: fit-content;
  margin-left: 5px;

  &::placeholder {
    color: rgba(82, 94, 104, 0.5);
  }
`;

const DateInput = styled.input`
  ${commonInputStyles}
  background-color: white;
  width: 130px;
`;

const FilterButton = styled.button`
  ${commonInputStyles}
  background-color: #c5ddf5;
  color: rgba(82, 94, 104, 1);
  font-weight: 600;
  cursor: pointer;
  padding: 0 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #b0d0f0;
  }
`;

const ResetButton = styled(FilterButton)`
  background-color: rgba(0, 0, 0, 0.3);
  color: white;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const CharterFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  font-weight: 800;
  font-size: 12px;

  @media (max-width: ${screenSizes.large.max}) {
    display: none;
  }
`;

const customStyles = {
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'rgba(197, 221, 245, 0.7)',
    borderRadius: '15px',
    margin: '2px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(82, 94, 104, 1)',
    whiteSpace: 'nowrap',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'rgba(82, 94, 104, 1)',
    fontSize: '11px',
    padding: '2px 6px',
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'rgba(82, 94, 104, 1)',
    backgroundColor: state.isSelected ? '#c5ddf5' : 'white',
    fontSize: '13px',
    padding: '8px 12px',
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '35px',
    height: '35px',
    border: '0',
    backgroundColor: 'white',
    borderRadius: '30px',
    boxShadow: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '35px',
    padding: '0 10px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '35px',
  }),
};

const CustomSelect = ({ options, onChange, value, placeholder, ...props }) => (
  <Select
    options={options}
    isMulti
    filterOption={createFilter({ matchFrom: 'start' })}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    styles={customStyles}
    formatOptionLabel={(option, { context }) => {
      if (context === 'value') {
        return option.label;
      }
      return option.label;
    }}
    {...props}
  />
);

const InlineFilters = ({
  countries = [],
  activationTypes = [],
  initialFilters,
  products,
  selectedFilters,
  setSelectedFilters,
  setProducts,
  setIsLoading,
  setCurrentPage,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isCharterActivatedYes, setIsCharterActivatedYes] = useState(false);
  const [isCharterActivatedNo, setIsCharterActivatedNo] = useState(false);
  const [everLoaded, setEverLoaded] = useState(false);
  const transformedCountriesValues = countries?.reduce((acc, country) => {
    return [...acc, { value: country, label: country }];
  }, []);

  const transformedActivationTypes = activationTypes?.reduce((acc, activationType) => {
    return [...acc, { value: activationType, label: activationType }];
  }, []);

  const getDefaultDateTo = () => format(new Date(), 'yyyy-MM-dd');
  const getDefaultDateFrom = () =>
    format(new Date(new Date().setMonth(new Date().getMonth() - 12)), 'yyyy-MM-dd');

  const handleResetFiltersOnClick = () => {
    setIsLoading(true);

    let is_charter = isCharterActivatedYes ? true : false;
    if ((is_charter && isCharterActivatedNo) || (!is_charter && !isCharterActivatedNo)) {
      is_charter = null;
    }

    const postParams = {
      date_from: getDefaultDateFrom(),
      date_to: getDefaultDateTo(),
      title: '',
      is_charter: null,
      is_active: false,
    };

    const queryParams = new URLSearchParams({
      date_from: getDefaultDateFrom(),
      date_to: getDefaultDateTo(),
      region: '',
      activation_type: '',
      title: ''.toString(),
      is_charter: is_charter,
    }).toString();

    console.log('check reset post params : ', postParams);

    history.push(`?${queryParams}`);

    axios
      .post('/our_products/', postParams)
      .then((response) => {
        setProducts(response.data);
        console.log('check reset res : ', response.data);
        setIsLoading(false);
        setCurrentPage(0);
      })
      .catch((err) => {});
  };

  const handleApplyFiltersOnClick = () => {
    setIsLoading(true);
    const countries = selectedFilters?.region?.reduce((acc, country) => {
      return [...acc, country.value];
    }, '');

    const activationTypes = selectedFilters?.activation_type?.reduce((acc, activationType) => {
      return [...acc, activationType.value];
    }, '');

    let is_charter = isCharterActivatedYes ? true : false;
    if ((is_charter && isCharterActivatedNo) || (!is_charter && !isCharterActivatedNo)) {
      is_charter = null;
    }

    const postParams = {
      date_from: selectedFilters.date_from || getDefaultDateFrom(),
      date_to: selectedFilters.date_to || getDefaultDateTo(),
      region: countries,
      activation_type: activationTypes,
      title: selectedFilters?.title || '',
      is_charter,
      is_active: selectedFilters?.is_active || false,
    };

    if (postParams.region === '') {
      delete postParams.region;
    }
    if (postParams.activation_type === '') {
      delete postParams.activation_type;
    }
    if (!postParams.date_from) delete postParams.date_from;
    if (!postParams.date_to) delete postParams.date_to;

    const queryParams = new URLSearchParams({
      date_from: selectedFilters.date_from || '',
      date_to: selectedFilters.date_to || '',
      region: countries ? selectedFilters.region?.map((country) => country.value).join(',') : '',
      activation_type: activationTypes
        ? selectedFilters.activation_type?.map((activationType) => activationType.value).join(',')
        : '',
      title: (selectedFilters?.title || '').toString(),
      is_charter: is_charter,
    }).toString();

    history.push(`?${queryParams}`);

    axios
      .post('/our_products/', postParams)
      .then((response) => {
        setProducts(response.data);
        console.log('check apply res : ', response.data);
        setIsLoading(false);
        setCurrentPage(0);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleApplyFiltersOnClick();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedFilters]);

  const isValidDate = (date) => {
    return date && date !== 'null' && date !== 'undefined' && date !== '';
  };

  useEffect(() => {
    if (!products) {
      return;
    }

    if (everLoaded) {
      return;
    }

    setEverLoaded(true);
    const searchParams = new URLSearchParams(location.search);
    let dateFrom = searchParams.get('date_from') || getDefaultDateFrom();
    let dateTo = searchParams.get('date_to') || getDefaultDateTo();
    let region = searchParams.get('region');
    let activationType = searchParams.get('activation_type');
    let title = searchParams.get('title');
    const isCharter = searchParams.get('is_charter');
    let regionArray = [];
    let activationTypeArray = [];
    let regionArrayForPost = [];
    let activationTypeArrayForPost = [];

    title = title === null || title === undefined ? '' : title;
    if (region) {
      regionArray = region.split(',').map((country) => {
        return { value: country, label: country };
      });
      regionArrayForPost = region.split(',').map((country) => {
        return country;
      });
    }

    if (activationType) {
      activationTypeArray = activationType.split(',').map((activationType) => {
        return { value: activationType, label: activationType };
      });
      activationTypeArrayForPost = activationType.split(',').map((activationType) => {
        return activationType;
      });
    }

    dateFrom = isValidDate(dateFrom) ? dateFrom : '';
    dateTo = isValidDate(dateTo) ? dateTo : '';

    setSelectedFilters({
      date_from: dateFrom,
      date_to: dateTo,
      region: regionArray,
      activation_type: activationTypeArray,
      title: title,
      is_charter: isCharter,
    });

    setIsCharterActivatedYes(isCharter === 'true');
    setIsCharterActivatedNo(isCharter === 'false');
    let charter = null;
    charter = isCharter === 'true' ? true : isCharter === 'false' ? false : null;
    const postParams = {
      date_from: dateFrom,
      date_to: dateTo,
      title: title,
      is_charter: charter,
      is_active: false,
    };
    console.log('check apply post params : ', postParams);
    if (activationTypeArrayForPost.length > 0) {
      postParams.activation_type = activationTypeArrayForPost;
    }
    if (regionArrayForPost.length > 0) {
      postParams.region = regionArrayForPost;
    }

    axios
      .post('/our_products/', postParams)
      .then((response) => {
        setProducts(response.data);
        setIsLoading(false);
        setCurrentPage(0);
      })
      .catch((err) => {});
  }, [products]);

  const handleCharterChangeYes = () => {
    const value = !isCharterActivatedYes;
    setIsCharterActivatedYes(value);
    setIsCharterActivatedNo(false);
  };
  const handleCharterChangeNo = () => {
    const value = !isCharterActivatedNo;
    setIsCharterActivatedNo(value);
    setIsCharterActivatedYes(false);
  };

  const handleCharterChangeAll = () => {
    if (isCharterActivatedNo && isCharterActivatedYes) {
      setIsCharterActivatedYes(false);
      setIsCharterActivatedNo(false);
      return;
    }
    setIsCharterActivatedYes(true);
    setIsCharterActivatedNo(true);
  };

  const handleOnTitleChange = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      title: event.target.value,
    });
  };

  const handleCountriesChange = (values) => {
    setSelectedFilters({
      ...selectedFilters,
      region: values,
    });
  };

  const handleActivationTypesChange = (values) => {
    setSelectedFilters({
      ...selectedFilters,
      activation_type: values,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log('name', name);
    setSelectedFilters({
      ...selectedFilters,
      [name]: value,
    });
  };

  return (
    <Englobe>
      <SearchField>
        <MagnifyingGlass size={20} color="#909DA2" />
        <SearchInput
          type="text"
          id="text-search"
          placeholder="Search Title"
          onChange={handleOnTitleChange}
          value={selectedFilters?.title}
        />
      </SearchField>
      <HideOnSmallScreen>
        <CustomSelect
          options={transformedCountriesValues}
          onChange={handleCountriesChange}
          value={selectedFilters?.region}
          placeholder="Country list"
        />
      </HideOnSmallScreen>
      <DateInput
        type="date"
        placeholder="Start Date"
        name="date_from"
        value={selectedFilters?.date_from || ''}
        onChange={handleInputChange}
      />
      <DateInput
        type="date"
        placeholder="End Date"
        name="date_to"
        value={selectedFilters?.date_to || ''}
        onChange={handleInputChange}
      />
      <HideOnSmallScreen>
        <CustomSelect
          options={transformedActivationTypes}
          onChange={handleActivationTypesChange}
          value={selectedFilters?.activation_type}
          placeholder="Activation Type"
        />
      </HideOnSmallScreen>
      <CharterFilter>
        <span>Charter activated:</span>
        <label>
          <input
            type="checkbox"
            checked={isCharterActivatedYes}
            onChange={handleCharterChangeYes}
          />
          Yes
        </label>
        <label>
          <input type="checkbox" checked={isCharterActivatedNo} onChange={handleCharterChangeNo} />
          No
        </label>
        <label>
          <input
            type="checkbox"
            checked={isCharterActivatedNo && isCharterActivatedYes}
            onChange={handleCharterChangeAll}
          />
          All
        </label>
      </CharterFilter>
      <FilterButton onClick={handleApplyFiltersOnClick}>APPLY</FilterButton>
      <ResetButton onClick={handleResetFiltersOnClick}>RESET</ResetButton>
    </Englobe>
  );
};

export default InlineFilters;
