export const colors = {
  WHITE: '#fff',
  BLUE: '#3e8ede',
  DARK_BLUE: '#0e4d77',
  BLACK: '#333',
  GRAY_LIGHT: '#F9FAFA',
  GRAY: '#e6eaeb',
  RED: '#DB3E3E',
  DARK_GRAY: '#b0b4b4',
};
