import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';
import ServiceSupport from './ServiceSupportNew';

const Container = styled.div`
  width: 100%;
  background-color: #e4eef6;
  padding: 60px 0;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 80px 0;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 100px 0;
  }
`;

const InnerContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 0 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 0 60px;
  }
`;

const Description = styled.div`
  font-size: 13px;
  line-height: 1.6;
  margin-top: 20px;
  color: rgba(82, 94, 104, 1);
  font-weight: 900;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 15px;
  }
`;

const Text = styled.li`
  text-align: left;
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: 900;
  color: rgba(82, 94, 104, 1);

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 13px;
  }
`;

const ItemsWrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0;
  line-height: 1.5;
  margin-top: 30px;

  @media (min-width: ${screenSizes.medium.min}) {
    width: 100%;
    max-width: 400px;
    margin-top: 0;
  }
`;

const Link = styled.div`
  margin: 30px 0 0;
  width: fit-content;

  & a {
    color: ${colors.BLUE};
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h2`
  color: rgba(82, 94, 104, 1);
  font-size: 32px;
  font-weight: 900;
  text-align: left;
  margin-bottom: 30px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 48px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(82, 94, 104, 0.3);
`;

const ContentWrapper = styled.div`
  @media (min-width: ${screenSizes.medium.min}) {
    flex: 0 0 calc(65% - 40px);
    margin-right: 40px;
  }
`;

const Information = styled.h3`
  font-size: 18px;
  font-weight: 800;
  color: rgba(119, 139, 159, 1);
  font-family: 'Inter', sans-serif;
  margin-bottom: 20px;
`;

const ServiceItem = ({ title, description, subtitles = [], image_file: image }) => {
  const descriptionItems = description?.split('\n');

  const linkText = subtitles?.find((element) => element?.includes('<a'));
  const transformedSubs = subtitles?.filter((item) => !item?.includes('<a'));

  return (
    <Container>
      <InnerContainer>
        <Title>{title}</Title>
        <Wrapper id="service">
          <ContentWrapper>
            {descriptionItems.map((des, index) => (
              <Description key={index} dangerouslySetInnerHTML={{ __html: des }} />
            ))}
            {linkText && <Link dangerouslySetInnerHTML={{ __html: linkText }} />}
          </ContentWrapper>
          <ItemsWrapper>
            <Information>Information</Information>
            {Array.isArray(transformedSubs) &&
              transformedSubs?.map((item, index) => (
                <React.Fragment key={`item-${index}`}>
                  <Text dangerouslySetInnerHTML={{ __html: item }} />
                  {index === 0 && <Divider />}
                </React.Fragment>
              ))}
          </ItemsWrapper>
        </Wrapper>
        {title === 'Emergency Mapping' && <ServiceSupport />}
      </InnerContainer>
    </Container>
  );
};

export default ServiceItem;
