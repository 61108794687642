import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import HomeHeader from '../../components/site-header/HomeHeader';
import Button from '../../components/common/Button';
import { urls } from '../../constants/urls';
import screenSizes from '../../constants/screenSizes';

const defaultAboutUsData = {
  text: 'In an era marked by increasing disasters and conflicts, rapid and effective action is crucial. UNOSAT leverages satellite imagery to provide accurate, near real-time information across vast areas. Our expertise enables national and regional authorities to collect, integrate, and analyse geospatial data, thereby enhancing disaster resilience, humanitarian response, and sustainable development. UNOSAT is dedicated to promoting evidence-based decision-making for peace, security, and resilience through advanced geospatial technologies.\nUNOSAT, as the United Nations Satellite Centre and part of UNITAR, is mandated to provide satellite analysis, training, and capacity development to UN funds, programmes, and specialised agencies, upon request. We also support Member States with satellite imagery analysis and capacity building in geospatial information technologies, enhancing their ability to make informed decisions.',
  video_url: 'https://www.youtube.com/watch?v=1QavZY2J_Pg',
  image_file: '/static/unosat_filesystem/about_us/Sans-titre---1.png',
};

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const Content = styled.div`
   padding: 60px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(144, 165, 185, 1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 120px 20px 80px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 200px 80px 100px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 0 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 0 0px 100px; 
  }
`;

const Title = styled.h1`
  font-size: 24px;
  width: 100%;
  font-weight: 900;
  color: white;
  text-align: left;
  margin: 0;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 48px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 56px;
  }
`;

const Description = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  line-height: 1.6;
  margin-top: 20px;
  width: 100%;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 24px;
    margin-top: 20px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 28px;
    line-height: 1.3;
  }
`;

const TitleMandate = styled.h2`
  font-size: 20px;
  width: 100%;
  font-weight: 900;
  color: white;
  text-align: left;
  margin: 40px 0 0;
  line-height: 1.3;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 24px;
    margin-top: 60px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 28px;
    margin-top: 80px;
  }
`;

const DescriptionMandate = styled.p`
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  line-height: 1.6;
  margin-top: 15px;
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 18px;
    width: 75%;
    margin-bottom: 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 19px;
    width: 50%;
    margin-bottom: 60px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin: 30px 0;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: ${screenSizes.medium.min}) {
    margin: 40px 0;
    border-radius: 15px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    border-radius: 20px;
  }
`;

const Video = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const WorldImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 30px;

  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 40px;
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-start;
`;

const AboutUsNew = () => {
  const [aboutUsData, setAboutUsData] = useState(null);

  useEffect(() => {
    axios
      .get('/about')
      .then((response) => {
        setAboutUsData(response?.data);
      })
      .catch((error) => {
        console.log(error);
        setAboutUsData(defaultAboutUsData);
      });
  }, []);

  const handleOnUnitarClick = () => {
    window.open(urls.UNITAR_ABOUT_US, '_blank');
  };

  const description = aboutUsData?.text?.split('\n');

  return (
    <MainContainer>
      <HomeHeader />
      <Content>
        <Wrapper>
          <Title>About UNOSAT</Title>
          <Description>{description && description[0]}</Description>

          <TitleMandate>MANDATE</TitleMandate>
          <DescriptionMandate>{description && description[1]}</DescriptionMandate>
          <StyledButton onClick={handleOnUnitarClick} variant="white">
            ABOUT UNITAR
          </StyledButton>
          <VideoWrapper>
            <Video
              src={`https://www.youtube.com/embed/F3Xq6tnEMv0`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="UNOSAT Video"
            />
          </VideoWrapper>
          <Title>UNOSAT IN THE WORLD</Title>
          <WorldImage src={aboutUsData?.image_file} alt="UNOSAT in the World" />
        </Wrapper>
      </Content>
    </MainContainer>
  );
};

export default AboutUsNew;
