import React, { useState } from 'react';
import L from 'leaflet';
import { MapContainer, Marker, Popup, useMap } from 'react-leaflet';
import styled from '@emotion/styled';
import { colors } from '../../constants/colors';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { DynamicMapLayer, TiledMapLayer } from 'react-esri-leaflet';
import { Globe, Plus, Minus, X } from 'phosphor-react';
import Control from 'react-leaflet-custom-control';
import PopupMapItem from './PopupMapItem';
import LoadingDots from '../../components/common/LoadingDots';
import screenSizes from '../../constants/screenSizes';
import { activations } from '../../constants/activations';
import ProductPreviewMap from './product/ProductPreviewMap';
import { keyframes } from '@emotion/react';
// Import all icon assets
import cycloneIcon from '../../assets/activations/cyclone.svg';
import droughtIcon from '../../assets/activations/drought.svg';
import earthquakeIcon from '../../assets/activations/earthquake.svg';
import flashFloodIcon from '../../assets/activations/flash-flood.svg';
import heatwaveIcon from '../../assets/activations/heatwave.svg';
import tsunamiIcon from '../../assets/activations/tsunami.svg';
import floodIcon from '../../assets/activations/flood.svg';
import volcanoIcon from '../../assets/activations/volcano.svg';
import coldWaveIcon from '../../assets/activations/cold-wave.svg';
import famineIcon from '../../assets/activations/famine.svg';
import insectIcon from '../../assets/activations/insect-infestation.svg';
import snowIcon from '../../assets/activations/snow-avalanche.svg';
import techIcon from '../../assets/activations/technological-disaster.svg';
import tornadoIcon from '../../assets/activations/tornado.svg';
import windIcon from '../../assets/activations/violent-wind.svg';
import wildfireIcon from '../../assets/activations/wildfire.svg';
import environmentalImpact from '../../assets/activations/environmental-impact.svg';
import landslides from '../../assets/activations/landslide.svg';
import epidemic from '../../assets/activations/epidemic.svg';
import exercise from '../../assets/activations/exercise.svg';
import mudslide from '../../assets/activations/mudslide.svg';
import affected from '../../assets/activations/affected.svg';

const MapWrapper = styled.div`
  height: 80vh;
  @media (min-width: ${screenSizes.medium.min}) {
    height: 80vh;
  }
  @media (min-width: ${screenSizes.small}) {
    height: 90vh;
  }

  .iconStyles {
    background-color: ${colors.BLUE};
    border-radius: 50%;
    padding: 3px;
  }

  img.leaflet-marker-icon {
    background-color: ${colors.BLUE};
    border-radius: 50%;
    padding: 3px;
  }

  .marker-cluster {
    background-clip: padding-box;
    border-radius: 50%;
  }

  .marker-cluster div {
    width: 40px;
    height: 40px;
    margin: 2px;
    text-align: center;
    border-radius: 50%;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5);
  }

  .marker-cluster-small {
    background-color: rgba(157, 201, 245, 0.8);
  }

  .marker-cluster-small div {
    background-color: rgba(157, 201, 245, 1);
    color: #fff;
  }

  .marker-cluster-medium {
    background-color: rgba(118, 177, 236, 0.8);
  }

  .marker-cluster-medium div {
    background-color: rgba(118, 177, 236, 1);
    color: #fff;
  }

  .marker-cluster-large {
    background-color: rgba(62, 142, 222, 0.8);
  }

  .marker-cluster-large div {
    background-color: rgba(62, 142, 222, 1);
    color: #fff;
  }
`;

const StyledPop = styled(Popup)`
  .leaflet-popup-content-wrapper {
    font-family: 'Open Sans', sans-serif;
     width: 314px; !important;
     position: relative;
     left: 180px !important;
     top: 150px !important;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    box-shadow: none;
  }

  .leaflet-popup-tip-container {
    visibility: hidden;
  }
`;

const StyledMarker = styled(Marker)`
  .leaflet-marker-pane {
    img.leaflet-marker-icon {
      background-color: ${colors.BLUE} !important;
    }
  }
`;

const ZoomControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 24px;
  padding: 4px;
  margin-bottom: 4vh;
`;


const ZoomButton = styled.button`
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #333;
  padding: 0;
  border: none;
  &:focus {
    outline: none;
  }
`;

const Divider = styled.div`
  width: 20px;
  height: 1px;
  background-color: #ccc;
  margin: 4px 0;
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const CloseButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1001;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;

const CustomZoomControl = () => {
  const map = useMap();
  const zoomDelta = 1;

  const handleZoomIn = () => {
    map.setZoom(map.getZoom() + zoomDelta);
  };

  const handleZoomOut = () => {
    map.setZoom(map.getZoom() - zoomDelta);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= parseInt(screenSizes.medium.min));

  return (
    <Control
      position="bottomleft"
      style={{ bottom: isMobile ? '12px' : '150px', left: isMobile ? '12px' : '20px' }}
    >
      <ZoomControlWrapper>
        <ZoomButton onClick={handleZoomIn} aria-label="Zoom in">
          <Plus size={18} weight="bold" />
        </ZoomButton>
        <Divider />
        <ZoomButton onClick={handleZoomOut} aria-label="Zoom out">
          <Minus size={18} weight="bold" />
        </ZoomButton>
      </ZoomControlWrapper>
    </Control>
  );
};

function calculateLogarithmicSize(childCount, baseSize = 20, maxSize = 180, growthFactor = 11) {
  if (childCount <= 0) return baseSize;

  // Utilise le logarithme naturel (ln) pour une croissance logarithmique
  let size = baseSize + Math.log(childCount) * growthFactor;

  // Assure que la taille ne dépasse pas maxSize
  return Math.min(Math.round(size), maxSize);
}

const CustomMarkerClusterGroup = ({ children }) => {
  return (
    <MarkerClusterGroup
      showCoverageOnHover={false}
      spiderfyOnMaxZoom={true}
      removeOutsideVisibleBounds={true}
      chunkedLoading={true}
      iconCreateFunction={(cluster) => {
        const childCount = cluster.getChildCount();
        let c = ' marker-cluster-';

        if (childCount < 4) {
          c += 'small';
        } else if (childCount < 7) {
          c += 'medium';
        } else {
          c += 'large';
        }
        let size = calculateLogarithmicSize(childCount);

        return L.divIcon({
          html: `<div style="width: ${size}px; height: ${size}px;"><span>${childCount}</span></div>`,
          className: 'marker-cluster' + c,
          iconSize: new L.Point(0, 0),
        });
      }}
    >
      {children}
    </MarkerClusterGroup>
  );
};

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const MapResults = ({ products, isLoading }) => {
  const [previewProductId, setPreviewProductId] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const createIcon = (url) => {
    return new L.Icon({
      iconUrl: url,
      iconSize: [27, 27],
      iconAnchor: [13, 4],
      popupAnchor: [-3, 5],
      className: 'leaflet-icon',
    });
  };
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closePreview();
    }
  };
  const getMarkerIcon = (type) => {
    switch (type) {
      case activations.FLOOD:
        return createIcon(floodIcon);
      case activations.TROPICAL_CYCLONE:
        return createIcon(cycloneIcon);
      case activations.WIND:
        return createIcon(windIcon);
      case activations.DROUGHT:
        return createIcon(droughtIcon);
      case activations.EARTHQUAKE:
        return createIcon(earthquakeIcon);
      case activations.FLASH_FLOOD:
        return createIcon(flashFloodIcon);
      case activations.HEAT_WAVE:
      case activations.EXTREME_TEMPERATURE:
        return createIcon(heatwaveIcon);
      case activations.TSUNAMI:
        return createIcon(tsunamiIcon);
      case activations.VOLCANO:
        return createIcon(volcanoIcon);
      case activations.WILD_FIRE:
      case activations.FIRE:
        return createIcon(wildfireIcon);
      case activations.ENVIRONMENTAL_IMPACT:
        return createIcon(environmentalImpact);
      case activations.LANDSLIDES:
        return createIcon(landslides);
      case activations.EPIDEMIC:
        return createIcon(epidemic);
      case activations.EXERCISE:
        return createIcon(exercise);
      case activations.MUD_SLIDE:
        return createIcon(mudslide);
      case activations.COLD_WAVE:
        return createIcon(coldWaveIcon);
      case activations.FAMINE:
        return createIcon(famineIcon);
      case activations.INSECT_INFESTATION:
        return createIcon(insectIcon);
      case activations.SNOW:
        return createIcon(snowIcon);
      case activations.TECH_DISASTER:
        return createIcon(techIcon);
      case activations.TORNADOES:
        return createIcon(tornadoIcon);
      default:
        return createIcon(affected);
    }
  };

  if (isLoading) {
    return <LoadingDots />;
  }

  const device = window.innerWidth <= 479 ? 'mobile' : 'desktop';
  console.log('products MAP RESLT', products);

  const closePreview = () => {
    setIsClosing(true);
    setTimeout(() => {
      setPreviewProductId(null);
      setIsClosing(false);
    }, 300);
  };
  return (
    <div>
      <MapWrapper>
        <MapContainer
          id="map"
          center={[49.009952, 2.548635]}
          minZoom={device === 'desktop' ? 3 : 1}
          maxZoom={10}
          scrollWheelZoom={true}
          style={{ zIndex: 0}}
          zoomControl={false}
          attributionControl={false}
          maxBounds={[
            [-90, -180],
            [90, 180],
          ]}
        >
          <DynamicMapLayer
            url={
              'https://cernunosat74.cern.ch/server/rest/services/UN_Basemap_LineMapService/MapServer/'
            }
          />
          <TiledMapLayer
            url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer"
            noWrap={true}
            bounds={[
              [-90, -180],
              [90, 180],
            ]}
          />
          <ChangeView center={[16.263981, -0.027987]} zoom={2} />
          <CustomZoomControl />
          <CustomMarkerClusterGroup>
            {products?.map(
              ({ map_event, activation_type, latitude, longitude, area_event_name }) => (
                <StyledMarker
                  position={[latitude, longitude]}
                  key={`products-map-${map_event.id}`}
                  icon={getMarkerIcon(activation_type)}
                >
                  <StyledPop>
                    <div id={`products-map-${map_event.id}`}>
                      {/* <PopupMapItem
                        title={map_event.title}
                        created_at={map_event.created_at}
                        id={map_event.id}
                        area_event_name={area_event_name}
                      /> */}
                      <>
                        {/* <CloseButton onClick={closePreview}>
                          <X size={24} />
                        </CloseButton> */}

                        <ProductPreviewMap
                          productId={map_event.id}
                          onClose={closePreview}
                          title={map_event.title}
                          area_event_name={area_event_name}
                        />
                      </>
                    </div>
                  </StyledPop>
                </StyledMarker>
              ),
            )}
          </CustomMarkerClusterGroup>
        </MapContainer>
      </MapWrapper>
    </div>
  );
};

export default MapResults;
