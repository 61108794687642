import ContentWrapper from '../../components/ContentWrapper';
import SectionTitle from '../../components/common/SectionTitle';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { colors } from '../../constants/colors';
import { CaretLeft, CaretRight, RocketLaunch } from 'phosphor-react';
import ReactPaginate from 'react-paginate';
import flatten from 'lodash/flatten';
import LoadingDots from '../../components/common/LoadingDots';
import { routes } from '../../constants/routes';
import screenSizes from '../../constants/screenSizes';
import { useLocation } from 'react-router-dom';

const Pagination = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding-top: 30px;

  li:not(.pagination__link--disabled) {
    cursor: pointer;
  }

  li {
    list-style: none;
    margin: 0 20px;
  }

  .pagination__link--disabled {
    color: #bababa;
  }

  .pagination__link--active {
    color: ${colors.BLUE};
    font-weight: 600;
  }
`;

const Content = styled.div`
  display: flex;
`;

const ResultsFilters = styled.div`
  display: none;

  @media (min-width: ${screenSizes.medium.min}) {
    display: block;
    margin-left: 13px;
    flex: 0 0 30%;
    margin-top: 20px;
  }
`;

const SearchItem = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid ${colors.DARK_BLUE};
  cursor: pointer;

  :first-child {
    padding-top: 0;
  }
`;

const ItemTitle = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4em;
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${colors.BLUE};
`;

const ItemCategory = styled.div`
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #aeb3b5;
`;

const Results = styled.div`
  @media (min-width: ${screenSizes.medium.min}) {
    flex: 0 0 70%;
  }
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

const Category = styled.div`
  padding: 15px 0 8px 5px;
  display: flex;
  justify-content: space-between;
  width: 300px;
  cursor: pointer;
  border-bottom: 1px solid ${colors.BLUE};
  ${({ isSelected }) => isSelected && `background-color: ${colors.DARK_BLUE}`};
  ${({ isSelected }) => isSelected && `color: #fff`};

  & span {
    ${({ isSelected }) => (isSelected ? `color: #fff` : `color:${colors.BLUE}`)};
    margin-right: 5px;
    font-weight: 700;
  }
`;

const ResultsTitle = styled.p`
  width: 500px;
  margin: 0 auto;
  font-size: 24px;
  padding: 40px 0;
`;

const Filter = styled.div`
  width: 300px;
  background-color: ${colors.BLUE};
  padding: 10px 5px;
  color: #fff;
`;

const Section = styled.section`
  padding-top: 50px;
`;

const Search = styled.div`
  padding: 50px;
  display: flex;
  margin: 0 auto;
  width: 80%;
`;

const SearchInput = styled.div`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(226, 226, 226);
  border-radius: 3px;
  margin-right: 4px;
  width: 90%;
`;

const Input = styled.input`
  height: 78px;
  width: 100%;
  border: 0;
  outline: none;
  font-size: 16px;
  letter-spacing: 3px;
  padding: 10px 30px;
`;

const GoButton = styled.button`
  background-color:  ${colors.BLUE};
  border: 1px solid rgb(226, 226, 226);
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  padding: 0 20px;
  height: 80px;
  margin-right: 4px;
`;

const SearchPage = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get('q');
  const [selectedContent, setSelectedContent] = useState('ALL');
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);
  const [newsStories, setNewsStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [newSearchValue, setNewSearchValue] = useState('');

  const handleOnSubmit = () => {
    history.push(routes.SEARCH + '?q=' + newSearchValue, { newSearchValue });
  };

  const handleOnChange = (e) => {
    setNewSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleOnSubmit();
    }
  };

  useEffect(() => {
    if (!searchValue) {
      return;
    }
    console.log(searchValue);
    setIsLoading(true);

    axios
      .post('/searchServer/', { search_term: searchValue })
      .then((response) => {
        setServices(
          response?.data?.services?.map((service) => ({ ...service, category: 'services' })),
        );

        setProducts(
          response?.data?.products?.map((product) => ({ ...product, category: 'products' })),
        );

        setNewsStories(
          response?.data?.news_stories?.map((news_storie) => ({
            ...news_storie,
            category: 'news',
          })),
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error);
      });
  }, [searchValue]);

  const getResults = () => {
    if (selectedContent === 'PRODUCTS') {
      return products;
    }

    if (selectedContent === 'SERVICES') {
      return services;
    }

    if (selectedContent === 'NEWS') {
      return newsStories;
    }

    return flatten([services, newsStories, products]);
  };

  const results = getResults();

  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 10;

  const offset = currentPage * PER_PAGE;

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    const isScrollBehaviorSupported = 'scrollBehavior' in document.documentElement.style;

    if (isScrollBehaviorSupported) {
      document.getElementById('results').scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const pageCount = Math.ceil(results?.length / PER_PAGE);

  const handleOnItemClick = (result) => () => {
    if (result.category === 'products') {
      history.push({
        pathname: `/products/${result.id}`,
      });
    }

    if (result.category === 'services') {
      history.push(routes.SERVICES, { selectedService: result.title });
    }

    if (result.category === 'news') {
      window.open(result?.link, '_blank');
    }
  };

  const currentPageData = results?.slice(offset, offset + PER_PAGE).map((result) => {
    return (
      <>
        <SearchItem onClick={handleOnItemClick(result)}>
          <ItemTitle>{result?.title}</ItemTitle>
          <ItemCategory>{result?.category}</ItemCategory>
          <div>{result?.description}</div>
        </SearchItem>
      </>
    );
  });

  return (
    <Section>
      <ContentWrapper id="results">
        <SectionTitle text="SEARCH" hasLine />
        <ResultsTitle>Results found for "{searchValue}"</ResultsTitle>
        {isLoading ? (
          <Loading>
            <LoadingDots />
          </Loading>
        ) : (
          <Content>
            <ResultsFilters>
              <Filter>By content type:</Filter>
              <div>
                <Category
                  isSelected={selectedContent === 'ALL'}
                  onClick={() => setSelectedContent('ALL')}
                >
                  ALL
                </Category>
                <Category
                  isSelected={selectedContent === 'SERVICES'}
                  onClick={() => setSelectedContent('SERVICES')}
                >
                  SERVICES <span>{services?.length || 0}</span>
                </Category>
                <Category
                  isSelected={selectedContent === 'PRODUCTS'}
                  onClick={() => setSelectedContent('PRODUCTS')}
                >
                  PRODUCTS <span>{products?.length || 0}</span>
                </Category>
                <Category
                  isSelected={selectedContent === 'NEWS'}
                  onClick={() => setSelectedContent('NEWS')}
                >
                  NEWS & STORIES <span>{newsStories?.length || 0}</span>
                </Category>
              </div>
            </ResultsFilters>
            <Results>
              {results.length > 0 ? (
                <>
                  <div>{currentPageData}</div>
                  <Pagination
                    previousLabel={<CaretLeft size={16} />}
                    nextLabel={<CaretRight size={16} />}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'pagination__link--active'}
                  />
                </>
              ) : (
                <>
                <Search>
                    <SearchInput>
                      <Input
                        type="text"
                        id="text-search"
                        placeholder="SEARCH"
                        value={newSearchValue}
                        onChange={handleOnChange}
                        onKeyDown={handleKeyDown}
                      />
                    </SearchInput>
                    <GoButton onClick={handleOnSubmit}>GO</GoButton>
                </Search>
                <div>No results found <RocketLaunch size={20} /></div>
                <a href="https://www.unitar.org/" style={{ textDecoration: 'none', color: 'black' }}>Click to find more information on UNITAR.org</a>
                </>
              )}
            </Results>
          </Content>
        )}
      </ContentWrapper>
    </Section>
  );
};

export default SearchPage;
