import ContentWrapper from '../../components/ContentWrapper';
import styled from '@emotion/styled';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';
import Support from './Support';

const Description = styled.div`
  font-size: 17px;
  line-height: 1.3;
  margin-top: 15px;

  @media (min-width: ${screenSizes.medium.min}) {
    text-align: center;
    font-size: 16px;
  }
`;

const Text = styled.li`
  flex: ${({ hasThreeItems }) => (hasThreeItems ? '0 0 30%;' : '0 0 40%;')};
  text-align: center;
  font-size: 17px;

  ::before {
    content: '';
    display: block;
    border-bottom: 2px solid ${colors.GRAY};
    width: 40%;
    margin: 10px auto 30px auto;
  }

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
  }
`;

const ItemsWrapper = styled.ul`
  list-style: none;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  line-height: 1.3;
  margin-bottom: 50px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding-top: 20px;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: ${({ hasThreeItems }) => (hasThreeItems ? '20px;' : '40px;')};
  }
`;

const Img = styled.img`
  width: 100%;
`;

const Link = styled.div`
  margin: 0 auto;
  width: fit-content;

  & a {
    color: ${colors.BLUE};
  }
`;

const ServicePage = ({ title, description, subtitles = [], image_file: image }) => {
  const hasThreeItems = subtitles.length === 3;
  const descriptionItems = description?.split('\n');

  const linkText = subtitles?.find((element) => element?.includes('<a'));
  const transformedSubs = subtitles?.filter((item) => !item?.includes('<a'));

  return (
    <>
      <Img src={image} />
      <ContentWrapper id="service">
        <div>
          {descriptionItems.map((des) => (
            <Description dangerouslySetInnerHTML={{ __html: des }} />
          ))}
        </div>
        <ItemsWrapper hasThreeItems={hasThreeItems}>
          {Array.isArray(transformedSubs) &&
            transformedSubs?.map((item, index) => (
              <Text
                key={`item=${index}`}
                hasThreeItems={hasThreeItems}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ))}
        </ItemsWrapper>
        {linkText && <Link dangerouslySetInnerHTML={{ __html: linkText }} />}
      </ContentWrapper>
      {title === 'Emergency Mapping' && <Support />}
    </>
  );
};

export default ServicePage;
