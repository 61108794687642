import styled from '@emotion/styled';
import { colors } from '../../constants/colors';
import ContentWrapper from '../../components/ContentWrapper';
import screenSizes from '../../constants/screenSizes';
import { routes } from '../../constants/routes';
import YoutubeEmbed from '../../components/common/YoutubeEmbed';

const Wrapper = styled.div`
  padding: 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 50px;
  }
`;

const Header = styled.h3`
  width: 270px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 30px;

  @media (min-width: 600px) {
    width: 380px;
  }
`

const Heading = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.BLUE};
  text-align: center;
  margin-bottom: 0;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 28px;
  }
`;

const Subheading = styled.span`
  font-size: 20px;
  color: ${colors.BLACK};
  text-align: center;
  margin-top: 0;
  font-weight: 600;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 28px;
  }
`;

const StepWrapper = styled.div`
  position: relative;

  #first-number {
    top: -45px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    #first-number {
      top: -17%;
    }
  }
`;

const Steps = styled.div`
  line-height: 1.4
`;

const Number = styled.div`
  display: none;

  @media (min-width: ${screenSizes.medium.min}) {
    display: block;
    color: ${colors.GRAY};
    font-weight: 900;
    opacity: 0.5;
    position: absolute;
    top: -28px;
    font-size: 200px;
    margin: 0 -15px;

    &:nth-child(odd) {
      left: 0;
    }

    &:nth-child(even) {
      right: 0;
    }
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 270px;
    top: -30%;
    margin: 0;
  }
`;

const StepTitle = styled.h4`
  color: ${colors.BLUE};
  font-size: 18px;
  margin: 0;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  background-color: ${colors.GRAY};
  padding: 10px;
`

const Content = styled.div`
  display: flex;
  margin: 10px 0;
  flex-direction: column;
  font-size: 18px;
  
  a {
    color: ${colors.BLUE};
  }
  
  @media (min-width: ${screenSizes.medium.min}) {
    min-height: 180px;
    margin-left: 115px;
    margin-right: 115px;
    font-size: 16px;

    &:not(:first-child) {
      margin-right: 0;
    }

    &:after {
      content: '';
      background-color: ${colors.BLUE};
      width: 3px;
      height: 100px;
      margin-left: 250px;
    }
  }

  @media (min-width: ${screenSizes.large.min}) {
    margin-left: 215px;
    margin-right: 215px;

    &:after {
      margin-left: 400px;
    }
  }
`;

const FooterNote = styled.div`
  text-align: center;
  padding-top: 10px;
  font-size: 18px;

  & a {
    color: ${colors.BLUE};
  }

  @media (min-width: ${screenSizes.medium.min}) {
    padding-top: 10px;
    font-size: 16px;
  }
`;

const LastSection = styled.div`
  font-size: 18px;
  margin-bottom: 30px;

  a {
    color: ${colors.BLUE};
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 16px;
    margin-left: 215px;
    margin-right: 215px;
  }
`;

const VideoWrapper = styled.div`
  margin-top: 50px;
`;

const List = styled.p`
  line-height: 1.9;
  background: linear-gradient(${colors.BLUE},${colors.BLUE}) 100% 0, linear-gradient(${colors.BLUE},${colors.BLUE}) 100% 0, linear-gradient(${colors.BLUE},${colors.BLUE}) 0 100%, linear-gradient(${colors.BLUE},${colors.BLUE}) 0 100%, #FFF;
  padding: 5px;
  background-size: 80px 3px,3px 80px;
  background-origin: content-box;
  background-repeat: no-repeat;
`

const Support = () => (
  <Wrapper>
    <ContentWrapper>
      <Header>
        <Heading>ACTIVATE </Heading>
        <Subheading>THE EMERGENCY MAPPING SERVICE</Subheading>
      </Header>
      <Steps>
        <StepWrapper>
          <Number id="first-number">1</Number>
          <Content>
            <div>
              <StepTitle>1. SEND A REQUEST</StepTitle>
              <p>
                No specific format is required for submitting requests, but the following
                information should be included:
              </p>
              <ul>
                <li>Event type (for example tropical storm, flood, earthquake, conflict);</li>
                <li>
                  Geographic area/location, ideally sent as KML file or latitude/longitude
                  coordinates, however place names are also acceptable;
                </li>
                <li>
                  Contact information UNOSAT can reach requestor at: name, function, e-mail, office
                  phone and mobile phone;
                </li>
              </ul>
              <p>
                Submit requests via e-mail to <a href="mailto:emergencymapping@unosat.org">emergencymapping@unosat.org</a>. Requests can also be submitted to 24/7 hotline directly if no
                internet connection available for e-mail: <a href="tel:+41754114998"> +41754114998</a>
              </p>
            </div>
          </Content>
        </StepWrapper>
        <StepWrapper>
          <Content>
            <StepTitle>2. GET FEEDBACK</StepTitle>
            <Paragraph>
              We will assess the request upon reception. If the Rapid Mapping service is activated,
              a plan including the satellite imagery acquisition and product delivery will be shared
              within the next few hours.
            </Paragraph>
          </Content>
          <Number>2</Number>
        </StepWrapper>
        <StepWrapper>
          <Number>3</Number>
          <Content>
            <StepTitle>3. PRODUCTION AND FOLLOW UP</StepTitle>
            <Paragraph>
              Suitable satellite imagery is immediately acquired by UNOSAT from various sources,
              including freely available data, commercial providers as well as the International
              Charter Space and Major Disasters. We then start the analysis and notify the
              requesting entity about the product delivery.
            </Paragraph>
          </Content>
        </StepWrapper>
        <StepWrapper>
          <Content>
            <StepTitle>4. DELIVERY</StepTitle>
            <Paragraph>
              Satellite imagery derived products are sent to the requesting entity, and may be
              disseminated to relevant humanitarian relief organizations. They are also available on
              our website and other platforms for digital download in different formats: GIS-ready
              data, maps, live maps, reports & statistics.
            </Paragraph>
          </Content>
          <Number>4</Number>
        </StepWrapper>
        <>
          <LastSection>
            <StepTitle>WHO CAN REQUEST?</StepTitle>
            <List>
              <ul>
                <li>UN offices and agencies</li>
                <li>Governmental agencies</li>
                <li>Red Cross and Red Crescent Movement (ICRC and IFRC)</li>
                <li>International and regional organizations</li>
                <li>Humanitarian Non-Governmental Organizations (NGOs)</li>
              </ul>
            </List>
          </LastSection>
        </>
      </Steps>
      <FooterNote>
        Consult all Emergency Mapping products{' '}
        <a href={routes.PRODUCTS} target="_blank" rel="noreferrer">
          here
        </a>
      </FooterNote>
      <VideoWrapper>
        <YoutubeEmbed embedId={'GF3cmxgebyo'} />
      </VideoWrapper>
    </ContentWrapper>
  </Wrapper>
);

export default Support;
