import image from '../../assets/home-page-header.png';
import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import Button from '../../components/common/Button';
import { routes } from '../../constants/routes';
import { urls } from '../../constants/urls';
import { useHistory } from 'react-router';
import ContentWrapper from '../../components/ContentWrapper';

const Header = styled.header`
  width: 100%;
`;

const Heading = styled.h1`
  position: relative;
  color: #fff;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 0;
  width: 280px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 38px;
    width: 350px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 44px;
  }
`;

const Description = styled.p`
  display: block;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 20px;
  padding: 10px 0;

  @media (min-width: ${screenSizes.medium.min}) {
    margin-bottom: 10px;
    font-size: 18px;
    width: 100%;
  }

  @media (min-width: ${screenSizes.large.min}) {
    width: 60%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;

  & button:first-of-type {
    margin-right: 10px;
  }

  @media (min-width: ${screenSizes.medium.min}) {
    & button:first-of-type {
      margin-right: 20px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url('${image}');
  background-size: cover;

  @media (min-width: ${screenSizes.large.min}) {
    min-height: 500px;
    justify-content: space-around;
  }
`;

const VideoHeader = () => {
  const history = useHistory();

  const handleOnUnosatClick = () => {
    history.push(routes.ABOUT_US);
  };

  const handleOnUnitarClick = () => {
    window.open(urls.UNITAR_ABOUT_US, '_blank');
  };

  return (
    <Header>
      <Content>
        <ContentWrapper>
          <Heading>FROM IMAGERY TO IMPACT</Heading>
          <Description>
            The United Nations Satellite Centre (UNOSAT), part of UNITAR, delivers satellite
            analysis, training, and capacity development to UN entities and Member States. We
            leverage geospatial technologies to support humanitarian response, sustainable
            development, and resilience building worldwide, all through voluntary contributions.
          </Description>
          <ButtonWrapper>
            <Button variant="white" onClick={handleOnUnosatClick}>
              About UNOSAT
            </Button>
            <Button variant="ghost" onClick={handleOnUnitarClick}>
              About UNITAR
            </Button>
          </ButtonWrapper>
        </ContentWrapper>
      </Content>
    </Header>
  );
};

export default VideoHeader;
