import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';
import { routes } from '../../constants/routes';

const Container = styled.div`
  width: 100%;
  background-color: rgba(120, 141, 160, 0.8);
  color: white;
  padding: 40px 20px;
  margin-top: 80px;
  border-radius: 20px;
`;

const InnerContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.h2`
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 30px;
`;

const StepWrapper = styled.div`
  margin-bottom: 40px;
`;

const StepTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const StepTitleS = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
`;

const StepContent = styled.div`
  padding: 20px;
  border-radius: 5px;
  font-size: 15px;
  line-height: 21px;
  font-weight: 900;

  p {
    white-space: pre-wrap;
    width: 100%;
  }
`;

const RequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: row;
  }
`;

const RequestInfo = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

const InfoRow = styled.div`
  display: flex;
  margin-bottom: 15px;
  ${({ hasBorder }) =>
    hasBorder &&
    `
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 15px;
  `}
`;

const LabelContainer = styled.div`
  width: 120px; // Ajustez cette valeur selon vos besoins
  flex-shrink: 0;
`;

const Label = styled.div`
  font-weight: 700;
  background: rgba(82, 94, 104, 0.3);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  border-radius: 10px;
  padding: 5px 10px;
  height: fit-content;
  display: inline-block;
`;

const Value = styled.div`
  flex: 1;
  padding-left: 20px; // Espace entre le label et la valeur
`;

const ButtonGroup = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -100px;
  margin-left: 40px;
`;

const Button = styled.button`
  background: rgba(255, 255, 255, 0.7);
  color: rgba(82, 94, 104, 1);
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 800;
  font-size: 13px;
  width: fit-content;
  transition: background-color 0.3s;

  &:hover {
    background-color: #34495e;
  }
`;

const ContactInfo = styled.div`
  font-size: 13px;
  margin-top: 10px;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  font-size: 15px;
  line-height: 21px;
  font-weight: 700;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  font-size: 15px;
  &:before {
    content: '•';
    color: white;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const FooterNote = styled.div`
  margin-top: 40px;
  font-size: 15px;
  color: rgba(82, 94, 104, 1);
  font-weight: 900;
  line-height: 21px;
  a {
    color: rgba(82, 94, 104, 1);

    text-decoration: underline;
  }
`;

const ServiceSupport = () => (
  <Container>
    <InnerContainer>
      <Header>Activate the Emergency Mapping Service</Header>

      <StepWrapper>
        <StepTitle>1. SEND A REQUEST</StepTitle>
        <StepContent>
          <p style={{ width: '50%' }}>
            No specific format is required for submitting requests, but the following information
            should be included:
          </p>
          <RequestContainer>
            <RequestInfo>
              <InfoRow hasBorder>
                <LabelContainer>
                  <Label>EVENT TYPE</Label>
                </LabelContainer>
                <Value>
                  Event typology for example tropical storm, flood, earthquake, conflict
                </Value>
              </InfoRow>
              <InfoRow hasBorder>
                <LabelContainer>
                  <Label>LOCATION</Label>
                </LabelContainer>
                <Value>
                  Geographic area/location, ideally sent as KML file or latitude/longitude
                  coordinates, however place names are also acceptable
                </Value>
              </InfoRow>
              <InfoRow>
                <LabelContainer>
                  <Label>YOUR CONTACT</Label>
                </LabelContainer>
                <Value>
                  Contact information UNOSAT can reach requestor at: name, function, e-mail, office
                  phone and mobile phone
                </Value>
              </InfoRow>
            </RequestInfo>
            <ButtonGroup>
              <p>Submit requests via e-mail to emergencymapping@unosat.org</p>
              <Button>SEND EMAIL</Button>
              <p>Or call 24/7 hotline: +41754114998 (if no internet connection available)</p>
              <Button>CALL HOTLINE</Button>
            </ButtonGroup>
          </RequestContainer>
        </StepContent>
      </StepWrapper>

      <StepWrapper>
        <StepTitle>2. GET FEEDBACK</StepTitle>
        <StepContent>
          <p>
            We will assess the request upon reception. If the Rapid Mapping service is activated, a
            plan including the satellite imagery acquisition and product delivery will be shared
            within the next few hours.
          </p>
        </StepContent>
      </StepWrapper>

      <StepWrapper>
        <StepTitle>3. PRODUCTION AND FOLLOW UP</StepTitle>
        <StepContent>
          <p>
            Suitable satellite imagery is immediately acquired by UNOSAT from various sources,
            including freely available data, commercial providers as well as the International
            Charter Space and Major Disasters. We then start the analysis and notify the requesting
            entity about the product delivery.
          </p>
        </StepContent>
      </StepWrapper>

      <StepWrapper>
        <StepTitle>4. DELIVERY</StepTitle>
        <StepContent>
          <p>
            Satellite imagery derived products are sent to the requesting entity, and may be
            disseminated to relevant humanitarian relief organizations. They are also available on
            our website and other platforms for digital download in different formats: GIS-ready
            data, maps, live maps, reports & statistics.
          </p>
        </StepContent>
      </StepWrapper>

      <StepWrapper>
        <StepTitleS>WHO CAN REQUEST?</StepTitleS>
        <StepContent>
          <List>
            <ListItem>UN offices and agencies</ListItem>
            <ListItem>Governmental agencies</ListItem>
            <ListItem>Red Cross and Red Crescent Movement (ICRC and IFRC)</ListItem>
            <ListItem>International and regional organizations</ListItem>
            <ListItem>Humanitarian Non-Governmental Organizations (NGOs)</ListItem>
          </List>
        </StepContent>
      </StepWrapper>

      <FooterNote>
        Consult all Emergency Mapping products{' '}
        <a href={routes.PRODUCTS} target="_blank" rel="noreferrer">
          here
        </a>
      </FooterNote>
    </InnerContainer>
  </Container>
);

export default ServiceSupport;
