import { Switch } from 'react-router-dom';
import { routes } from '../../constants/routes';
import Route from './Route';
import ScrollToTop from '../../utils/scrolling';
import OurPartnersPage from '../../pages/partners/OurPartnersPage';
import ServicesPage from '../../pages/services/ServicesPage';
import HomePage from '../../pages/home/HomePage';
import ProductsPage from '../../pages/products/ProductsPage';
import ContactUsPage from '../../pages/contact-us/ContactUsPage';
import AboutUsPageV2 from '../../pages/about/AboutUsPageV2';
import ProductDetailsPage from '../../pages/products/product/ProductDetails';
import SearchPage from '../../pages/search/SearchPage';

import HomeNew from '../../pages/home/HomePageNew';
import ProductNew from '../../pages/products/ProductPageNew';
import ServiceNew from '../../pages/services/ServicePageNew';
import PartnerNew from '../../pages/partners/PartnerPageNew';
import ProjectNew from '../../pages/projects/ProjectPage';
import AboutUsNew from '../../pages/about/AboutPageNew';
import ContactUsNew from '../../pages/contact-us/ContactPageNew';

const Content = () => (
  <>
    <ScrollToTop />
    <Switch>
      {routes.HOME && <Route exact path={routes.HOME} component={HomeNew} />}
      {routes.SEARCH && <Route exact path={routes.SEARCH} component={SearchPage} />}
      {routes.ABOUT_US && <Route exact path={routes.ABOUT_US} component={AboutUsNew} />}
      {routes.PARTNERS && <Route exact path={routes.PARTNERS} component={PartnerNew} />}
      {routes.PRODUCTS && <Route exact path={routes.PRODUCTS} component={ProductNew} />}
      {routes.PRODUCT && <Route exact path={routes.PRODUCT} component={ProductDetailsPage} />}
      {routes.SERVICES && <Route exact path={routes.SERVICES} component={ServiceNew} />}
      {routes.CONTACT && <Route exact path={routes.CONTACT} component={ContactUsNew} />}
      {routes.PROJECTS && <Route exact path={routes.PROJECTS} component={ProjectNew} />}
    </Switch>
  </>
);

export default Content;
