import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import HomeHeader from '../../components/site-header/HomeHeader';
import LoadingDots from '../../components/common/LoadingDots';
import screenSizes from '../../constants/screenSizes';

const defaultPartners = [
  {
    id: 10,
    partner_name: 'AnsuR Technologies',
    accronym: 'AnsuR',
    icon: '/static/unosat_filesystem/partners/AnsuR_logo_SVG.svg',
    link: 'https://www.ansur.no/',
    category: 'Private Sector',
  },
  {
    id: 11,
    partner_name: 'The European Space Agency',
    accronym: 'esa',
    icon: '/static/unosat_filesystem/partners/ESA_logo_PNG.png',
    link: 'https://www.esa.int/',
    category: 'Regional Organizations',
  },
  {
    id: 12,
    partner_name: 'esri',
    accronym: 'esri',
    icon: '/static/unosat_filesystem/partners/esri_logo_PNG.png',
    link: 'https://www.esri.com/en-us/home',
    category: 'Private Sector',
  },
  {
    id: 14,
    partner_name: 'International Committee of the Red Cross',
    accronym: 'ICRC',
    icon: '/static/unosat_filesystem/partners/ICRC_logo_png.png',
    link: 'https://www.icrc.org/fr',
    category: 'International Organizations',
  },
  {
    id: 15,
    partner_name: 'The Norwegian Agency for Development Cooperation',
    accronym: 'Norad',
    icon: '/static/unosat_filesystem/partners/Norad_logo_PNG.png',
    link: 'https://www.norad.no/en/front/',
    category: 'National Organizations',
  },
  {
    id: 18,
    partner_name: 'United Nations Office for the Coordination of Humanitarian Affairs',
    accronym: 'UNOCHA',
    icon: '/static/unosat_filesystem/partners/OCHA_logo_PNG.png',
    link: 'https://www.unocha.org/',
    category: 'UN',
  },
  {
    id: 44,
    partner_name: 'United Nations Economic and Social Commission for Asia and the Pacific',
    accronym: 'UNESCAP',
    icon: '/static/unosat_filesystem/partners/UNESCAP_logo.png',
    link: 'https://www.unescap.org/',
    category: 'UN',
  },
  {
    id: 13,
    partner_name: 'IGAD Climate Prediction and Applications Centre',
    accronym: 'ICPAC',
    icon: '/static/unosat_filesystem/partners/ICPAC_logo__green_PNG.png',
    link: 'https://www.icpac.net/',
    category: 'Regional Organizations',
  },
  {
    id: 19,
    partner_name: 'Satellite Applications Catapult',
    accronym: 'Catapult',
    icon: '/static/unosat_filesystem/partners/Satellite%20Applications%20Catapult.png',
    link: 'https://sa.catapult.org.uk/',
    category: 'National Organizations',
  },
  {
    id: 20,
    partner_name: 'Arab Bank for Economic Development in Africa',
    accronym: 'BADEA',
    icon: '/static/unosat_filesystem/partners/BADEA_logo.jpg',
    link: 'https://www.badea.org/index.htm',
    category: 'Regional Organizations',
  },
  {
    id: 25,
    partner_name: 'United Nations Global Pulse',
    accronym: 'Global Pulse',
    icon: '/static/unosat_filesystem/partners/UN%20Global%20Pulse.png',
    link: 'https://www.unglobalpulse.org/',
    category: 'UN',
  },
  {
    id: 28,
    partner_name: 'International Criminal Court',
    accronym: 'ICC',
    icon: '/static/unosat_filesystem/partners/icc-logo%202.png',
    link: 'https://www.icc-cpi.int/',
    category: 'International Organizations',
  },
  {
    id: 33,
    partner_name: 'Norway Ministry of Foreign Affairs',
    accronym: 'NMFA',
    icon: '/static/unosat_filesystem/partners/NMFA_logo.jpg',
    link: 'https://www.regjeringen.no/en/dep/ud/id833/',
    category: 'National Organizations',
  },
  {
    id: 34,
    partner_name: 'United Nations Human Rights Office of the High Commissioner',
    accronym: 'OHCHR',
    icon: '/static/unosat_filesystem/partners/OHCHR_logo_blue_PNG.png',
    link: 'https://www.ohchr.org/',
    category: 'UN',
  },
  {
    id: 38,
    partner_name: 'The Commonwealth Secretariat',
    accronym: 'The Commonwealth Secretariat',
    icon: '/static/unosat_filesystem/partners/The%20Commonwealth%20Secretariat.jpg',
    link: 'https://thecommonwealth.org/',
    category: 'International Organizations',
  },
  {
    id: 40,
    partner_name: 'United Nations Alliance of Civilizations',
    accronym: 'UNAOC',
    icon: '/static/unosat_filesystem/partners/UNAOC.png',
    link: 'https://www.unaoc.org/',
    category: 'UN',
  },
  {
    id: 41,
    partner_name: 'United Nations Development Programme',
    accronym: 'UNDP',
    icon: '/static/unosat_filesystem/partners/UNDP_logo.png',
    link: 'https://www.undp.org/',
    category: 'UN',
  },
  {
    id: 42,
    partner_name: 'United Nations Office for Disaster Risk Reduction',
    accronym: 'UNDRR',
    icon: '/static/unosat_filesystem/partners/UNDRR.png',
    link: 'https://www.undrr.org/',
    category: 'UN',
  },
  {
    id: 43,
    partner_name: 'United Nations Environment Programme',
    accronym: 'UNEP',
    icon: '/static/unosat_filesystem/partners/UNEP_logo_blue.png',
    link: 'https://www.unep.org/',
    category: 'UN',
  },
  {
    id: 46,
    partner_name: 'United Nations High Commissionner for Refugees',
    accronym: 'UNHCR',
    icon: '/static/unosat_filesystem/partners/UNHCR_logo_blue.png',
    link: 'https://www.unhcr.org/',
    category: 'UN',
  },
  {
    id: 47,
    partner_name: 'United Nations Operations and Crisis Centre',
    accronym: 'UNOCC',
    icon: '/static/unosat_filesystem/partners/UN_logo_blue.png',
    link: 'https://wwww.un.org',
    category: 'UN',
  },
  {
    id: 30,
    partner_name: 'International, Impartial and Independent Mechanism for the Syrian Arab Republic',
    accronym: 'IIIM',
    icon: '/static/unosat_filesystem/partners/UN_logo_blue.png',
    link: 'https://iiim.un.org/',
    category: 'UN',
  },
  {
    id: 29,
    partner_name: 'International Institute for Education Planning - UNESCO',
    accronym: 'IIEP UNESCO',
    icon: '/static/unosat_filesystem/partners/iiep-unesco.png',
    link: 'http://www.iiep.unesco.org/en',
    category: 'UN',
  },
  {
    id: 45,
    partner_name: 'United Nations Educational, Scientific and Cultural Organization',
    accronym: 'UNESCO',
    icon: '/static/unosat_filesystem/partners/UNESCO_logo.png',
    link: 'https://www.unesco.org/en',
    category: 'UN',
  },
  {
    id: 16,
    partner_name: 'United Nations Human Rights Office of the High Commissioner',
    accronym: 'OHCHR',
    icon: '/static/unosat_filesystem/partners/OHCHR_logo_PNG.png',
    link: 'https://www.ohchr.org/',
    category: 'UN',
  },
  {
    id: 17,
    partner_name: 'United Nations Economic and Social Commission for Asia and the Pacific',
    accronym: 'UNESCAP',
    icon: '/static/unosat_filesystem/partners/UNESCAP-75_logo_PNG.png',
    link: 'https://www.unescap.org/',
    category: 'UN',
  },
  {
    id: 49,
    partner_name: 'Geneva Science-Policy Interface',
    accronym: 'GSPI',
    icon: '/static/unosat_filesystem/partners/Logo%20GSPI.png',
    link: 'https://gspi.ch/',
    category: 'Nonprofit Organizations',
  },
];

const Content = styled.div`
  padding: 60px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(144, 165, 185, 1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 120px 20px 80px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 200px 80px 100px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 0 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 0 0px 100px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  width: 100%;
  font-weight: 900;
  color: white;
  text-align: left;
  margin: 0;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 48px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 56px;
  }
`;

const Description = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  line-height: 1.6;
  margin-top: 20px;
  width: 100%;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 24px;
    margin-top: 20px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 28px;
    line-height: 1.3;
  }
`;
const SelectWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: row;
    align-items: center;
  }
`;

const Label = styled.label`
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 18px;
    margin-right: 10px;
    margin-bottom: 0;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 60px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  appearance: none;
  padding-right: 30px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 10px center;

  @media (min-width: ${screenSizes.medium.min}) {
    width: auto;
    min-width: 200px;
    margin-left: 10px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  }

  & option {
    background-color: rgb(144, 165, 185);
    color: white;
    padding: 10px;
  }
`;

const PartnersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  width: 100%;
  margin-top: 30px;

  @media (min-width: ${screenSizes.medium.min}) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 40px;
  }
`;

const PartnerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PartnerLogoCard = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(73, 73, 73, 0.1);
  border-radius: 8px;
  padding: 15px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: 100%;
  height: 120px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 20px;
    height: 150px;
  }

  &:hover {
    transform: translateY(-5px);
    background-color: rgba(73, 73, 73, 0.2);
  }
`;

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const PartnerLogo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;

  @media (min-width: ${screenSizes.medium.min}) {
    width: 100px;
    height: 100px;
  }
`;

const PartnerName = styled.h3`
  margin-top: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-left: 5px;
  width: 100%;
  font-family: 'Inter', sans-serif;

  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 10px;
    font-size: 16px;
    margin-left: 10px;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const PartnerNew = () => {
  const [selectedOption, setSelectedOption] = useState('ALL');
  const [isLoading, setIsLoading] = useState(true);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    axios
      .get('/our_partners')
      .then((response) => {
        setPartners(response?.data || []);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPartners(defaultPartners);
        setIsLoading(false);
      });
  }, []);

  const categories = ['ALL', ...new Set(partners.map((partner) => partner.category))];

  const filteredPartners =
    selectedOption === 'ALL'
      ? partners
      : partners.filter((partner) => partner.category === selectedOption);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <MainContainer>
      <HomeHeader />
      <Content>
        <Wrapper>
          <Title>Our Partners</Title>
          <Description>
            UNOSAT collaborates with various organizations to support evidence-based decision-making
            for peace, security, and resilience. Explore our network of partners below.
          </Description>
          <SelectWrapper>
            <Label htmlFor="partner-filter">Partner List</Label>
            <Select id="partner-filter" value={selectedOption} onChange={handleSelectChange}>
              <option value="ALL" style={{ backgroundColor: 'rgb(144, 165, 185)' }}>
                List of Partners
              </option>
              {categories
                .filter((cat) => cat !== 'ALL')
                .map((category) => (
                  <option
                    key={category}
                    value={category}
                    style={{ backgroundColor: 'rgb(144, 165, 185)' }}
                  >
                    {category}
                  </option>
                ))}
            </Select>
          </SelectWrapper>
          {isLoading ? (
            <Loading>
              <LoadingDots />
            </Loading>
          ) : (
            <PartnersGrid>
              {filteredPartners.map((partner) => (
                <PartnerCard key={partner.id}>
                  <PartnerLogoCard href={partner.link} target="_blank" rel="noopener noreferrer">
                    <PartnerLogo src={partner.icon} alt={partner.partner_name} />
                  </PartnerLogoCard>
                  <PartnerName>{partner.accronym || partner.partner_name}</PartnerName>
                </PartnerCard>
              ))}
            </PartnersGrid>
          )}
        </Wrapper>
      </Content>
    </MainContainer>
  );
};

export default PartnerNew;
