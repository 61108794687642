import React from 'react';
import styled from '@emotion/styled';
import { SquaresFour, List } from 'phosphor-react';
import screenSizes from '../../constants/screenSizes';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${screenSizes.medium.max}) {
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;

  @media (max-width: ${screenSizes.medium.max}) {
    padding: 0;
    justify-content: center;
  }
`;

const ActivationTitle = styled.h2`
  color: rgba(144, 165, 185, 0.4);
  font-size: 19px;
  font-weight: 700;
  font-family: 'Inter';

  @media (max-width: ${screenSizes.medium.max}) {
    color: rgba(82, 94, 104, 0.6);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: ${screenSizes.medium.max}) {
    display: none;
  }
`;

const ToggleButton = styled.button`
  padding: 10px;
  border: none;
  background-color: transparent;
  color: rgba(82, 94, 104, 1);
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(82, 94, 104, 0.1);
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const ViewToggleButtons = ({ view, setView }) => {
  return (
    <Container>
      <ContentContainer>
        <ActivationTitle>{view === 'list' ? 'Activation list' : 'Activation grid'}</ActivationTitle>
        <ButtonsContainer>
          <ToggleButton onClick={() => setView('list')} aria-label="List View">
            <List weight={view === 'list' ? 'fill' : 'regular'} color="rgba(82, 94, 104, 1)" />
          </ToggleButton>
          <ToggleButton onClick={() => setView('grid')} aria-label="Grid View">
            <SquaresFour
              weight={view === 'grid' ? 'fill' : 'regular'}
              color="rgba(82, 94, 104, 1)"
            />
          </ToggleButton>
        </ButtonsContainer>
      </ContentContainer>
    </Container>
  );
};

export default ViewToggleButtons;
