import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import screenSizes from '../../constants/screenSizes';
import SortOption from './SortOption';
import { colors } from '../../constants/colors';
import SectionTitle from '../../components/common/SectionTitle';
import partnersImage from '../../assets/partners-header.jpg';
import { urls } from '../../constants/urls';
import axios from 'axios';
import LoadingDots from '../../components/common/LoadingDots';
import ContentWrapper from '../../components/ContentWrapper';

const Description = styled.p`
  color: ${colors.BLACK};
  font-size: 16px;
  line-height: 1.3;
  margin-left: 13px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  width: 100%;
  font-size: 14px;

  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: row;
    padding-top: 100px;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding-left: 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    flex: 0 0 30%;
    margin-bottom: 0;
    padding-left: 0;
  }
`;

const Image = styled.img`
  background-color: #fff;
  object-fit: contain;
  border-radius: 30px;
  width: 150px;
  height: 150px;
  padding: 15px;

  @media (min-width: ${screenSizes.medium.min}) {
  }
`;

const Partners = styled.div`
  display: flex;
  color: ${colors.BLACK};
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${screenSizes.medium.min}) {
    flex: 70%;
    margin-top: 0;
    justify-content: flex-start;
  }
`;

const Partner = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 50%;
  cursor: pointer;
  padding: 20px;
  text-decoration: none;
  color: ${colors.BLACK};

  @media (min-width: ${screenSizes.small.min}) {
    flex: 0 0 33%;
  }

  @media (min-width: ${screenSizes.large.min}) {
    flex: 0 0 23%;
  }

  &:hover {
    background-color: #fff;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  font-weight: 600;
  margin-top: 15px;
  text-align: center;
`;

const ImageHeader = styled.img`
  object-fit: cover;
  width: 100%;
  height: 200px;
  background: linear-gradient(12deg, #66acea, #0d2b3d);

  @media (min-width: ${screenSizes.medium.min}) {
    height: 400px;
  } 
  
  @media (min-width: ${screenSizes.large.min}) {
    height: 500px;
  }
`

const Divider = styled.div`
  content: '';
  border-top: 2px solid ${colors.BLUE};
  width: 100%;
  margin-left: 20px;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

const Subtitle = styled.p`
  align-self: center;
  color: #333;
  font-size: 16px;
  padding-top: 60px;
  

  & a {
    color: ${colors.BLUE};
  }

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
    width: 420px;
    margin: 0 auto;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.GRAY_LIGHT};
  padding-top: 40px;
`;

const WrapperColumn = styled.div`
  width: 100%;
`

const OurPartnersPage = () => {
  const [selectedOption, setSelectedOption] = useState('ALL');
  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get('/our_partners')
      .then((response) => {
        setPartners(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const categories = ['ALL', ...new Set(partners?.map((partner) => partner.category))];

  const filteredResults =
    selectedOption === 'ALL'
      ? partners?.sort((a, b) => a.accronym.localeCompare(b.accronym))
      : partners?.filter((item) => item.category === selectedOption).sort((a, b) => a.accronym.localeCompare(b.accronym));

  return (
    <>
      <ImageHeader src={partnersImage} />
      <PageWrapper>
        <ContentWrapper>
          <Header>
            <SectionTitle text="PARTNERS" />
            <Divider />
          </Header>
          <Description>
            UNOSAT works with United Nations funds, programmes, specialized agencies, and UN Member
            States, as well as other International and Regional organizations to support
            evidence-based decision-making for peace, security and resilience.
          </Description>
          { isLoading || !partners ? (
            <Loading>
              <LoadingDots />
            </Loading>
            ) : (
            <Wrapper>
              <OptionsWrapper>
                {categories.map((category) => (
                  <SortOption
                    key={`category-${category}`}
                    type={category}
                    setSelectedOption={setSelectedOption}
                    isSelected={category === selectedOption}
                  />
                ))}
              </OptionsWrapper>
              <WrapperColumn>
                <Partners>
                  {filteredResults.map(({ accronym: name, icon, link, id }) => (
                    <Partner key={`partner-${id}`} href={link} target="_blank">
                      <Image src={icon} />
                      <Name>{name?.toUpperCase()}</Name>
                    </Partner>
                  ))}
                </Partners>
                <Subtitle>
                  Read more about the work accomplished via{' '}
                  <a href={urls.UNITAR_PROJECTS} target="_blank" rel="noreferrer">our projects</a>
                </Subtitle>
              </WrapperColumn>

            </Wrapper>
          )}
        </ContentWrapper>
      </PageWrapper>
    </>
  );
};

export default OurPartnersPage;
