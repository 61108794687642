import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { contactForms } from '../../constants/contactForms';
import LoadingDots from '../../components/common/LoadingDots';
import axios from 'axios';

const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 60px;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const FormSelectionButtons = styled.fieldset`
  border: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  gap: 10px;
  padding: 0;
  flex-wrap: wrap;
`;

const ButtonSend = styled.button`
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  color: rgba(82, 94, 104, 1);
  border: none;
  background: #fff;
  line-height: 28px;
  border-radius: 40px;
  width: fit-content;
  margin-top: 10px;
  padding: 10px 30px;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 8px 20px;
  }
`;

const SelectionButton = styled.label`
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  line-height: 20px;
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(119, 139, 159, 0.3)' : 'rgba(255, 255, 255, 0.4)'};
  color: ${({ isSelected }) => (isSelected ? 'rgba(82, 94, 104, 1)' : 'rgba(82, 94, 104, 0.5)')};
  border-radius: 20px;
  flex: 1 1 30%;
  min-width: 200px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 10px 15px;
    min-width: 150px;
  }
`;

const EmergencyInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const EmergencyInfoBlock = styled.div`
  flex: 0 0 45%;
  min-width: 250px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    min-width: auto;
  }
`;

const Description = styled.div`
  text-align: left;
  font-weight: 900;
  color: rgba(82, 94, 104, 1);
  margin-bottom: 10px;
  line-height: 25px;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const ActionButton = styled.button`
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  background: rgba(255, 255, 255, 0.7);
  color: rgba(82, 94, 104, 1);
  border: none;
  line-height: 16px;
  border-radius: 20px;
  width: fit-content;
  margin-top: 10px;
  padding: 6px 30px;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 5px 20px;
  }
`;

const FieldsGroup = styled.fieldset`
  border: none;
  margin-bottom: 30px;
  margin-top: 60px;
  padding: 0;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  flex: 0 0 48%;
  min-width: 250px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    min-width: auto;
  }
`;

const InputField = styled.input`
  border: none;
  border-bottom: 1px solid rgba(82, 94, 104, 0.3);
  width: 100%;
  padding: 10px 0;
  font-size: 14px;
  color: rgba(82, 94, 104, 1);
  background: transparent;
  &:focus {
    outline: none;
    border-bottom-color: rgba(82, 94, 104, 1);
  }
`;

const Label = styled.label`
  display: block;
  font-size: 13px;
  font-weight: 800;
  font-family: 'Inter', sans-serif;
  color: rgba(119, 139, 159, 1);
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const MessageInput = styled.textarea`
  border: 1px solid rgba(82, 94, 104, 0.3);
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 14px;
  color: rgba(82, 94, 104, 1);
  resize: vertical;
  background: transparent;
  &:focus {
    outline: none;
    border-color: rgba(82, 94, 104, 1);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Error = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const FormContent = styled.div`
  width: 100%;
`;

const ContactUsForm = ({ setShowSuccessMessage, setShowErrorMessage }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      to_email: contactForms.EMERGENCY_MAPPING,
      name: '',
      organization: '',
      from_email: '',
      email_subject: '',
      email_message: '',
    },
  });
  const [submitting, setSubmitting] = useState(false);
  const watchFormEmail = watch('to_email');

  const onSubmit = (data) => {
    setSubmitting(true);
    console.log(data);

    axios
      .post('/contact_us/', data)
      .then((response) => {
        setSubmitting(false);
        reset();
        setShowSuccessMessage(true);
      })
      .catch((err) => {
        setSubmitting(false);
        setShowErrorMessage(true);
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormSelectionButtons>
        <SelectionButton
          isSelected={watchFormEmail === contactForms.EMERGENCY_MAPPING}
          onClick={() => setValue('to_email', contactForms.EMERGENCY_MAPPING)}
        >
          FOR EMERGENCY MAPPING
        </SelectionButton>
        <SelectionButton
          isSelected={watchFormEmail === contactForms.MEDIA}
          onClick={() => setValue('to_email', contactForms.MEDIA)}
        >
          FOR MEDIA
        </SelectionButton>
        <SelectionButton
          isSelected={watchFormEmail === contactForms.GENERAL}
          onClick={() => setValue('to_email', contactForms.GENERAL)}
        >
          FOR GENERAL ENQUIRIES
        </SelectionButton>
      </FormSelectionButtons>

      <FormContent>
        {watchFormEmail === contactForms.EMERGENCY_MAPPING && (
          <EmergencyInfoContainer>
            <EmergencyInfoBlock>
              <Description>Submit requests via e-mail to emergencymapping@unosat.org.</Description>
              <ActionButton onClick={() => copyToClipboard('emergencymapping@unosat.org')}>
                SEND EMAIL
              </ActionButton>
            </EmergencyInfoBlock>
            <EmergencyInfoBlock>
              <Description>
                Requests can also be submitted to 24/7 hotline directly if no internet connection
                available for e-mail: +41754114998
              </Description>
              <ActionButton onClick={() => copyToClipboard('+41754114998')}>
                CALL HOTLINE
              </ActionButton>
            </EmergencyInfoBlock>
          </EmergencyInfoContainer>
        )}
        <Description>
          For any other enquiries related to the emergency mapping service, please use the contact
          form below.
        </Description>
        <FieldsGroup>
          <InputGroup>
            <InputWrapper>
              <Label>Name</Label>
              <InputField {...register('name', { required: 'Name is required' })} />
              {errors.name && <Error>{errors.name.message}</Error>}
            </InputWrapper>
            <InputWrapper>
              <Label>Organization</Label>
              <InputField {...register('organization', { required: 'Organization is required' })} />
              {errors.organization && <Error>{errors.organization.message}</Error>}
            </InputWrapper>
          </InputGroup>
          <InputWrapper>
            <Label>Email</Label>
            <InputField
              {...register('from_email', {
                required: 'Email is required',
                pattern: { value: isValidEmail, message: 'Invalid email address' },
              })}
            />
            {errors.from_email && <Error>{errors.from_email.message}</Error>}
          </InputWrapper>
          <InputWrapper>
            <Label>Subject</Label>
            <InputField {...register('email_subject', { required: 'Subject is required' })} />
            {errors.email_subject && <Error>{errors.email_subject.message}</Error>}
          </InputWrapper>
          <InputWrapper>
            <Label>Message</Label>
            <MessageInput {...register('email_message', { required: 'Message is required' })} />
            {errors.email_message && <Error>{errors.email_message.message}</Error>}
          </InputWrapper>
        </FieldsGroup>
      </FormContent>
      <ButtonWrapper>
        {submitting ? <LoadingDots /> : <ButtonSend type="submit">SEND MESSAGE</ButtonSend>}
      </ButtonWrapper>
    </Form>
  );
};

export default ContactUsForm;
