import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import { colors } from '../../constants/colors';

const Option = styled.div`
  font-size: 14px;
  color: ${colors.BLACK};
  margin-right: 20px;
  ${({ isSelected }) => isSelected && `color: ${colors.BLUE}`};
  ${({ isSelected }) => isSelected && `border-bottom: 2px solid ${colors.BLUE}`};
  ${({ isSelected }) => isSelected && ` font-weight: 600`};
  cursor: pointer;
  margin-bottom: 8px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
    margin-top: 10px;
    padding-left: 18px;
    margin-bottom: 10px;
    border-bottom: 0;
    ${({ isSelected }) => isSelected && `border-left: 2px solid ${colors.BLUE}`};
  }
`;

const SortOption = ({ type, isSelected, setSelectedOption }) => {
  const handleOnClick = () => {
    setSelectedOption(type);
  };

  return (
    <Option key={type} onClick={handleOnClick} isSelected={isSelected}>
      {type.toUpperCase()}
    </Option>
  );
};

export default SortOption;
