import React, { Fragment, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import axios from 'axios';
import { Timeline } from 'react-twitter-widgets';
import { colors } from '../../constants/colors';
import { urls } from '../../constants/urls';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const defaultNewsAndStories = [
  {
    title: 'Geospatial Decision Support for Climate Resilience in the Pacific',
    description:
      'A series of tailored training was delivered by UNOSAT experts in November and December 2022 in 3 countries of the Pacific: Vanuatu, the Solomon Islands and Fiji. Building up on past activities, these sessions aimed to enhance GIS capacity for Climate Resilience.',
    image: '/static/unosat_filesystem/news_stories/GDS4CR_SolomonIslands_27112022_72.jpg',
    link: 'https://unitar.org/about/news-stories/news/geospatial-decision-support-climate-resilience-pacific',
    category: 'News',
  },
  {
    title: 'Partnership between CBAS & UNOSAT',
    description:
      '27 June 2023, Beijing, China - The United Nations Institute for Training and Research (UNITAR) and The International Research Center of Big Data for Sustainable Development Goals (CBAS) are pleased to announce the signing of a Memorandum of Understanding (MoU).  The partnership between CBAS and UNOSAT comes at a crucial time when the world faces complex challenges that require innovative and data-driven solutions.',
    image: '/static/unosat_filesystem/news_stories/2_62.png',
    link: 'https://unitar.org/about/news-stories/featuredarticles/unitar-and-cbas-sign-memorandum-understanding',
    category: 'News',
  },
  {
    title:
      'Midline Event Workshop, Strengthening capacities in the use of GIT for improved resilience in Asia, Pacific & Africa',
    description:
      'UNOSAT organised the Midline Event workshop in June 2023 at Bangkok, Thailand for all the experts and stakeholders from its project partner countries . This event steered at focusing and addressing pertinent issues faced by the partner countries.',
    image: '/static/unosat_filesystem/news_stories/Picture1_49.jpg',
    link: 'https://unitar.org/about/news-stories/news/working-high-risks-countries-building-climate-resilience-through-geospatial-technology-asia-pacific',
    category: 'News',
  },
  {
    title:
      'Participation to Partnership: Recognising the Significance of Cooperation & Collaboration for Robust Capacity Development',
    description:
      'In November 2023 The United Nations Satellite Centre (UNOSAT) at Vanuatu in partnership with VanKIRAP, the Secretariat of the Pacific Regional Environment Programme (SPREP) extended the delivery of technical training in geospatial information technology at the subnational level.',
    image: '/static/unosat_filesystem/news_stories/Picture1%20vanuatu_0.png',
    link: 'https://www.unitar.org/about/news-stories/featuredarticles/participation-partnership-recognising-significance-cooperation-and-collaboration-robust-capacity',
    category: 'News',
  },
  {
    title: 'ADVANCED REMOTE SENSING FOR SUSTAINABLE LAND MANAGEMENT',
    description:
      '"These systems will really enhance and open more gateways for non-technological people who would appreciate the technology and use it better." Survey Engineer Mr Chokila from the National Land Commission Secretariat (NLCS)',
    image: '/static/unosat_filesystem/news_stories/remote%20sensing.png',
    link: 'https://unitar.org/about/news-stories/news/advanced-remote-sensing-sustainable-land-management',
    category: 'News',
  },
  {
    title:
      'UNESCO AND UN SATELLITE CENTRE JOIN FORCES TO SAFEGUARD UKRAINE’S CULTURAL HERITAGE WITH GEOSPATIAL TECHNOLOGIES',
    description:
      'From 27 to 30 November 2023, UNESCO and UNOSAT staff conducted training for twenty Ukrainian cultural professionals in Lviv, Ukraine. Read more!',
    image:
      '/static/unosat_filesystem/news_stories/Capture%20d%E2%80%99e%CC%81cran%202023-12-04%20a%CC%80%2016.53.23_0.png',
    link: 'https://unitar.org/about/news-stories/news/unesco-and-un-satellite-centre-join-forces-safeguard-ukraines-cultural-heritage-geospatial',
    category: 'News',
  },
  {
    title: "EMPOWERING WOMEN IN SCIENCE: UNOSAT'S IMPACTFUL WORK IN GEOSPATIAL ANALYSIS",
    description:
      'UNOSAT, with women like Michelle de Gruchy, Katarina Palmkron, and Joy Papao leading efforts, exemplifies progress in leveraging satellite imagery and geospatial analysis for addressing global challenges. Their passion, resilience, and leadership underscore the importance of gender equality and empowerment in science and technology.',
    image: '/static/unosat_filesystem/news_stories/Katinka.jpg',
    link: 'https://unitar.org/about/news-stories/news/empowering-women-science-unosats-impactful-work-geospatial-analysis',
    category: 'News',
  },
  {
    title: 'EMPOWERING DISASTER RESILIENCE IN BANGLADESH: GEOSPATIAL TRAINING FOR PROGRESS',
    description:
      'In Dhaka, Bangladesh, strengthening GIS capacities is crucial for disaster resilience. A recent NORAD and UNOSAT-supported training program focused on enhancing disaster management skills through QGIS software, resulting in increased competence and highlighting the importance of geospatial data for risk management.',
    image:
      '/static/unosat_filesystem/news_stories/Capture%20d%E2%80%99e%CC%81cran%202024-01-18%20a%CC%80%2010.56.36_0.png',
    link: 'https://unitar.org/about/news-stories/news/empowering-disaster-resilience-bangladesh-geospatial-training-progress',
    category: 'News',
  },
  {
    title: "UNOSAT'S EMPOWERMENT IN DISASTER RISK MANAGEMENT",
    description:
      "In Vientiane, Lao PDR, a NORAD-funded training program by UNOSAT focused on geospatial technologies for disaster risk management. The program boosted participants' proficiency and confidence levels in Earth observation and remote sensing techniques, aligning effectively with training objectives.",
    image:
      '/static/unosat_filesystem/news_stories/Capture%20d%E2%80%99e%CC%81cran%202024-01-18%20a%CC%80%2010.49.47.png',
    link: 'https://unitar.org/about/news-stories/news/unosats-empowerment-disaster-risk-management-navigating-geospatial-technologies-resilience-lao-pdr',
    category: 'News',
  },
];

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #e4eef6;
`;

const Section = styled.section`
  padding-top: 60px;
  width: 100%;
  z-index: 0;

  @media (min-width: 768px) {
    padding-top: 100px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100px;

  @media (min-width: 1024px) {
    width: 60%;
    padding-bottom: 180px;
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: stretch;
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-dots {
    bottom: -50px;
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: rgba(144, 165, 185, 0.7);
  }

  .slick-dots li.slick-active button:before {
    color: rgba(144, 165, 185, 1);
  }
`;

const CarouselItemContainer = styled.div`
  position: relative;
  padding: 25px;
  max-width: 98%;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  border-radius: 20px;
  background-color: rgba(144, 165, 185, 1);

  @media (min-width: 768px) {
    padding: 35px;
    height: 600px;
    border-radius: 30px;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  margin-bottom: 60px;

  @media (min-width: 768px) {
    height: 200px;
    margin-bottom: 180px;
  }
`;

const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TextLabel = styled.p`
  font-size: 12px;
  line-height: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: ${colors.WHITE};
  text-transform: uppercase;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 28px;
  }
`;

const Title = styled.h2`
  color: ${colors.WHITE};
  font-size: 28px;
  line-height: 32px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 41px;
  }
`;

const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 2px solid rgba(82, 94, 104, 1);
  margin: 0 0 15px 0;

  @media (min-width: 768px) {
    border-top-width: 3px;
    margin: 0 0 20px 0;
  }
`;

const Description = styled.p`
  color: rgba(82, 94, 104, 1);
  font-size: 14px;
  line-height: 20px;
  font-weight: 800;
  margin-bottom: 0;
  max-width: 600px;
  flex-grow: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 23px;
    -webkit-line-clamp: 5;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;

  @media (min-width: 768px) {
    margin-top: 80px;
  }
`;

const Button = styled.button`
  padding: 6px 10px;
  font-size: 12px;
  width: auto;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: ${colors.WHITE};
  background-color: rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }

  @media (min-width: 768px) {
    font-size: 13px;
  }
`;

const ArrowButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 5;
  color: rgba(119, 139, 159, 1);
  font-size: 30px;
  display: none;

  @media (min-width: 768px) {
    font-size: 40px;
    display: block;
  }
`;

const PrevArrow = styled(ArrowButton)`
  left: 10px;

  @media (min-width: 768px) {
    left: 40px;
  }
`;

const NextArrow = styled(ArrowButton)`
  right: 10px;

  @media (min-width: 768px) {
    right: 40px;
  }
`;

const ArrowSVG = styled.svg`
  width: 18px;
  height: 36px;
  fill: rgba(255, 255, 255, 0.5);
  transition: fill 0.3s ease;

  &:hover {
    fill: rgba(255, 255, 255, 0.8);
  }

  @media (min-width: 768px) {
    width: 22px;
    height: 44px;
  }
`;

const TwitterWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 100%;
    height: 600px;
    background-color: #f0f3f5;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
  }

  @media (min-width: 1024px) {
    width: 40%;
  }
`;

const TwitterLoadingPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f3f5;
  color: #90a5b9;
  font-size: 18px;
  font-weight: bold;
`;

const NewsAndStoriesNew = () => {
  const [newsAndStories, setNewsAndStories] = useState(null);
  const [isTwitterLoaded, setIsTwitterLoaded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    axios
      .get('/news_stories')
      .then((response) => {
        setNewsAndStories(response?.data);
      })
      .catch((error) => {
        console.log(error);
        setNewsAndStories(defaultNewsAndStories);
      });
  }, []);

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <PrevArrow onClick={onClick}>
        <ArrowSVG viewBox="0 0 22 44">
          <path d="M20 4L4 22L20 40" stroke="currentColor" strokeWidth="4" fill="none" />
        </ArrowSVG>
      </PrevArrow>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <NextArrow onClick={onClick}>
        <ArrowSVG viewBox="0 0 22 44">
          <path d="M2 4L18 22L2 40" stroke="currentColor" strokeWidth="4" fill="none" />
        </ArrowSVG>
      </NextArrow>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <MainContainer>
      <Section>
        <ContentWrapper>
          <NewsWrapper>
            <CarouselWrapper>
              {newsAndStories && (
                <Slider {...settings}>
                  {newsAndStories.map(({ title, description, category, link }, index) => (
                    <CarouselItemContainer key={index}>
                      <TopSection>
                        <TextLabel>{category.toUpperCase()}</TextLabel>
                        <Title>{title}</Title>
                      </TopSection>
                      <MiddleSection>
                        <Divider />
                        <Description>{description}</Description>
                        <ButtonWrapper>
                          <Button onClick={() => window.open(link, '_blank')}>
                            {category === 'Stories' ? 'SEE THIS STORY' : 'SEE NEWS'}
                          </Button>
                          <Button
                            onClick={() =>
                              window.open(
                                category === 'Stories' ? urls.UNITAR_STORIES : urls.UNITAR_NEWS,
                                '_blank',
                              )
                            }
                          >
                            {category === 'Stories' ? 'SEE ALL STORIES' : 'SEE ALL NEWS'}
                          </Button>
                        </ButtonWrapper>
                      </MiddleSection>
                    </CarouselItemContainer>
                  ))}
                </Slider>
              )}
            </CarouselWrapper>
            {windowWidth >= 768 && (
              <TwitterWrapper>
                {!isTwitterLoaded && (
                  <TwitterLoadingPlaceholder>Loading Twitter Feed...</TwitterLoadingPlaceholder>
                )}
                <Timeline
                  dataSource={{
                    sourceType: 'profile',
                    screenName: 'unosat',
                  }}
                  options={{
                    height: '600',
                    width: '100%',
                  }}
                  onLoad={() => setIsTwitterLoaded(true)}
                />
              </TwitterWrapper>
            )}
          </NewsWrapper>
        </ContentWrapper>
      </Section>
    </MainContainer>
  );
};

export default NewsAndStoriesNew;
