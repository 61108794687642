import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { MagnifyingGlass, X, List } from 'phosphor-react';
import { routes } from '../../constants/routes';
import screenSizes from '../../constants/screenSizes';
import { useToggle } from '../../utils/toggle';
import Logo from '../common/Logo';
import SearchComponent from '../common/SearchComponent';

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
`;

const Header = styled.header`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 10%,
    rgba(0, 0, 0, 0.7) 20%,
    rgba(0, 0, 0, 0.6) 30%,
    rgba(0, 0, 0, 0.5) 40%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.3) 60%,
    rgba(0, 0, 0, 0.2) 70%,
    rgba(0, 0, 0, 0.1) 80%,
    rgba(0, 0, 0, 0.05) 90%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: 0 20px;

  @media (max-width: ${screenSizes.medium.max}) {
    background: linear-gradient(
      to bottom,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 0.7) 40%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0.3) 80%,
    rgba(0, 0, 0, 0) 100%
    );
    padding-top: 30px;
  }

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 0 80px;
    padding-top: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 20px 0;
  }
`;

const DesktopMenu = styled.nav`
  display: none;

  @media (min-width: ${screenSizes.large.min}) {
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    margin-left: 20px;
    gap: 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    gap: 16px;
  }

  @media (min-width: ${screenSizes.large.max}) {
    gap: 40px;
  }
`;

const MenuItem = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 15px;
  height: 37px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 13px;
  font-family: 'Inter';
  background-color: ${({ 'data-isactive': isActive }) =>
    isActive === 'true' ? 'white' : 'rgba(82, 94, 104, 0.4)'};
  color: ${({ 'data-isactive': isActive }) => (isActive === 'true' ? '#1c2833' : 'white')};
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: white;
    color: #1c2833;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 6px 18px;
    font-size: 11px;
    height: 30px;
  }

  @media (min-width: ${screenSizes.large.max}) {
    padding: 6px 15px;
    font-size: 13px;
    height: 37px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const MenuIcon = styled(IconButton)`
  margin-left: 10px;

  @media (min-width: ${screenSizes.large.min}) {
    display: none;
  }
`;

const BlurredOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 98;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const MobileMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  padding: 20px;
  z-index: 99;
  overflow-y: auto;

  @media (min-width: ${screenSizes.large.min}) {
    display: none;
  }
`;

const MobileMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 300px; // Limite la largeur pour un meilleur aspect sur les grands écrans
`;

const MobileMenuItem = styled(Link)`
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  transition: all 0.3s ease;
  text-align: center;
  width: 60%;
  border-radius: 30px;
  background: rgba(82, 94, 104, 0.4);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; // Assure que l'icône est blanche
`;

const HomeHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState('');
  const {
    isOpen: isSearchOpen,
    handleToggle: toggleSearch,
    handleClose: closeSearch,
  } = useToggle(false);
  const { isOpen: isMenuOpen, handleToggle: toggleMenu, handleClose: closeMenu } = useToggle(false);

  const handleSearchSubmit = () => {
    if (searchValue.trim()) {
      history.push(`${routes.SEARCH}?q=${searchValue}`, { searchValue });
      closeSearch();
    }
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const menuItems = [
    { to: routes.HOME, label: 'HOME' },
    { to: routes.PRODUCTS, label: 'PRODUCTS' },
    { to: routes.SERVICES, label: 'SERVICES' },
    { to: routes.PROJECTS, label: 'PROJECTS' },
    { to: routes.PARTNERS, label: 'PARTNERS' },
    { to: routes.ABOUT_US, label: 'ABOUT US' },
    { to: routes.CONTACT, label: 'CONTACT US' },
  ];

  return (
    <HeaderWrapper>
      <Header>
        <Wrapper>
          <Logo to={routes.HOME} />
          <DesktopMenu>
            {menuItems.map((item) => (
              <MenuItem
                key={item.to}
                to={item.to}
                data-isactive={(location.pathname === item.to).toString()}
              >
                {item.label}
              </MenuItem>
            ))}
          </DesktopMenu>
          <IconWrapper>
            <IconButton onClick={toggleSearch}>
              {isSearchOpen ? (
                <X size={24} color="#fff" />
              ) : (
                <MagnifyingGlass size={24} color="#fff" />
              )}
            </IconButton>
            <MenuIcon onClick={toggleMenu}>
              {isMenuOpen ? <X size={24} color="#fff" /> : <List size={24} color="#fff" />}
            </MenuIcon>
          </IconWrapper>
        </Wrapper>
      </Header>
      {isSearchOpen && (
        <SearchComponent
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleSearchSubmit={handleSearchSubmit}
          handleSearchKeyDown={handleSearchKeyDown}
        />
      )}
      <BlurredOverlay isOpen={isMenuOpen} />
      <MobileMenu isOpen={isMenuOpen}>
        <CloseButton onClick={closeMenu}>
          <X size={32} weight="bold" />
        </CloseButton>
        <MobileMenuItems>
          {menuItems.map((item) => (
            <MobileMenuItem key={item.to} to={item.to} onClick={closeMenu}>
              {item.label}
            </MobileMenuItem>
          ))}
        </MobileMenuItems>
      </MobileMenu>
    </HeaderWrapper>
  );
};

export default HomeHeader;
