import React from 'react';
import styled from '@emotion/styled';
import BackgroundImage from '../../assets/home-page-header-2.png';
import BackroundVideo from '../../assets/video-no-text.mp4';
import { Element } from 'react-scroll';
import screenSizes from '../../constants/screenSizes';
import HomeHeader from '../../components/site-header/HomeHeader';
import InformationalBanner from '../../components/common/InformationalBanner';
import ServiceSection from '../services/ServiceSectionForHome';
import NewsAndStoriesNew from './News';

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 20px 0;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 50px 0;
  }
`;

const TitleContainer = styled.div`
  margin-top: 80px; // Augmenté pour mobile

  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 60px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    margin-top: 100px;
  }
`;

const UNOSAT = styled.h1`
  font-size: 30px; // Augmenté pour mobile
  padding: 0 20px;
  width: 100%;
  font-weight: 700;
  color: white;
  margin: 0;
  text-decoration: none;
  text-align: left;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 1.2;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 48px;
    padding: 0 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 80px;
    padding: 0 80px;
    line-height: 1.0;
  }
`;

const HomeTitle = styled.h2`
  font-size: 30px; // Augmenté pour mobile
  padding: 0 20px;
  width: 100%;
  color: white;
  text-align: left;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin: 10px 0 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 0.8;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 32px;
    padding: 0 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 80px;
    padding: 0 80px;
    line-height: 1.0;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 20px;
  margin-bottom: 40px; // Augmenté pour mobile

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 0 40px;
    margin-bottom: 60px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 0 80px;
    margin-bottom: 160px;
  }
`;

const Description = styled.p`
  font-size: 16px; // Augmenté pour mobile
  line-height: 1.5;
  color: #fff;
  text-align: left;
  padding: 0px;
  max-width: 100%;
  font-weight: 500;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
    padding: 20px;
    max-width: 70%;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 18px;
    padding: 30px;
    max-width: 60%;
    font-weight: 700;
    line-height: 1.4;
    margin-top:15%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.4) 30%,
      rgba(0, 0, 0, 0.4) 60%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url('${BackgroundImage}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.4) 30%,
      rgba(0, 0, 0, 0.4) 60%,
      rgba(0, 0, 0, 0.6) 100%
    );
    z-index: 1;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

const HomePage = () => {
  const [canPlayVideo, setCanPlayVideo] = React.useState(false);

  React.useEffect(() => {
    // Vérifier si le navigateur peut lire la vidéo
    const video = document.createElement('video');
    setCanPlayVideo(video.canPlayType('video/mp4') !== '');
  }, []);

  return (
    <MainContainer>
      <HomeHeader />
      <Content>
        {canPlayVideo && (
          <VideoBackground autoPlay loop muted playsInline poster={BackgroundImage}>
            <source src={BackroundVideo} type="video/mp4" />
          </VideoBackground>
        )}
        <TextContainer>
          <TitleContainer>
            <UNOSAT>UNOSAT</UNOSAT>
            <HomeTitle>From Imagery to Impact</HomeTitle>
          </TitleContainer>
          <DescriptionContainer>
            <Description>
              The United Nations Satellite Centre (UNOSAT), part of UNITAR, delivers satellite
              analysis, training, and capacity development to UN entities and Member States. We
              leverage geospatial technologies to support humanitarian response, sustainable
              development, and resilience building worldwide.
            </Description>
          </DescriptionContainer>
        </TextContainer>
      </Content>
      <InformationalBanner />
      <Element name="servicesSection">
        <ServiceSection />
      </Element>
      <NewsAndStoriesNew />
    </MainContainer>
  );
};

export default HomePage;
