export const activations = {
  FLOOD: 'Floods',
  TROPICAL_CYCLONE: 'Tropical Cyclone',
  LANDSLIDES: 'Landslides',
  EPIDEMIC: 'Epidemic',
  ENVIRONMENTAL_IMPACT: 'Environmental Impact',
  EARTHQUAKE: 'Earthquake',
  OIL_SPLIT: 'Oil Spill',
  VOLCANO: 'Volcano',
  EXERCISE: 'Exercise / Simulation',
  FIRE: 'Fire',
  COLD_WAVE: 'Cold Wave',
  COMPLEX_EMERGENCY: 'Complex Emergency',
  DROUGHT: 'Drought',
  EXTRATROPICAL_CYCLONE: 'Extratropical Cyclone',
  EXTREME_TEMPERATURE: 'Extreme Temperature',
  FAMINE: 'Famine',
  FLASH_FLOOD: 'Flash Flood',
  HEAT_WAVE: 'Heat Wave',
  INSECT_INFESTATION: 'Insect Infestation',
  MUD_SLIDE: 'Mud Slide',
  SEVERE_LOCAL_STORM: 'Severe Local Storm',
  SNOW: 'Snow Avalanche',
  STORM_SURGE: 'Storm Surge',
  TECH_DISASTER: 'Tech. Disaster',
  TORNADOES: 'Tornadoes',
  TSUNAMI: 'Tsunami',
  WIND: 'Violent  Wind',
  WILD_FIRE: 'Wild Fire',
  NO_GLIDE: 'No glide',
  OTHER: 'Other',
};
