import styled from '@emotion/styled';
import ContactUsForm from './ContactUsForm';
import screenSizes from '../../constants/screenSizes';
import { useState } from 'react';
import Notification from '../../components/common/Notification';
import ContentWrapper from '../../components/ContentWrapper';
import HomeHeader from '../../components/site-header/HomeHeader';

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const Content = styled.div`
  padding: 60px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 120px 20px 80px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 200px 80px 100px;
  }

  @media (max-width: ${screenSizes.small.max}) {
    padding: 40px 0 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 0 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 0 0px 100px; 
  }

  @media (max-width: ${screenSizes.small.max}) {
    padding: 0 15px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  width: 100%;
  font-weight: 900;
  color: rgba(82, 94, 104, 1);
  text-align: left;
  margin: 0;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 48px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 35px;
  }

  @media (max-width: ${screenSizes.small.max}) {
    font-size: 24px;
  }
`;

const FormSection = styled.div`
  flex: 1 1 100%;
  width: 100%;

  @media (max-width: ${screenSizes.small.max}) {
    margin-top: 20px;
  }
`;

const ContactUsNew = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleOnErrorClose = () => {
    setShowErrorMessage(false);
  };

  return (
    <MainContainer>
      <HomeHeader />
      <Content>
        <Wrapper>
          <Title>CONTACT US</Title>
          <FormSection>
            {showSuccessMessage && (
              <Notification
                title="Your message has been submitted successfully"
                variant="confirmation"
              />
            )}
            {showErrorMessage && (
              <Notification
                title="Something went wrong, please try again"
                variant="warning"
                onClose={handleOnErrorClose}
              />
            )}
            <ContactUsForm
              setShowSuccessMessage={setShowSuccessMessage}
              setShowErrorMessage={setShowErrorMessage}
            />
          </FormSection>
        </Wrapper>
      </Content>
    </MainContainer>
  );
};

export default ContactUsNew;
