import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';

const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  ::after {
    display: block;
    content: '';
    padding-top: 56.25%;
  }
`;

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (min-width: ${screenSizes.medium.min}) {
  }
`;

const YoutubeEmbed = ({ embedId }) => (
  <VideoWrapper>
    <Video
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </VideoWrapper>
);

export default YoutubeEmbed;
