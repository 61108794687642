import { useCallback, useState } from 'react';

export const useToggle = (initState = false) => {
  const [isOpen, setIsOpen] = useState(initState);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return { isOpen, handleOpen, handleClose, handleToggle };
};
