import React from 'react';
import styled from '@emotion/styled';
import { DownloadSimple } from 'phosphor-react';
import screenSizes from '../../../constants/screenSizes';

const DownloadSection = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const DownloadLine = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  font-weight: 600;
  padding-bottom: 12px;
  color: rgba(82, 94, 104, 1);
  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
    line-height: 23px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const BoldLabel = styled.span`
  font-weight: 800;
  margin-bottom: 10px;

  @media (min-width: ${screenSizes.medium.min}) {
    flex: 0 0 22%;
    text-align: left;
    margin-bottom: 0;
  }
`;

const ContentPart = styled.span`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: ${screenSizes.medium.min}) {
    flex: 0 0 78%;
    text-align: left;
  }
`;

const DownloadItem = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 8px 28px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: fit-content;

  @media (min-width: ${screenSizes.medium.min}) {
    width: fit-content;
  }
`;

const DownloadLink = styled.a`
  color: rgba(82, 94, 104, 1);
  text-decoration: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  @media (min-width: ${screenSizes.medium.min}) {
    width: auto;
    justify-content: flex-start;
  }
`;

const IconWrapper = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const DownloadLinks = ({ title, pdfName, excel, wmap, gdp, shp, kml, wms, id }) => {
  return (
    <DownloadSection>
      <DownloadLine>
        <BoldLabel>Download Files</BoldLabel>
        <ContentPart>
          {pdfName && (
            <DownloadItem>
              <DownloadLink
                href={`/static/unosat_filesystem/${id}/${pdfName}`}
                download={pdfName}
                target="_blank"
              >
                <IconWrapper>
                  <DownloadSimple size={20} />
                </IconWrapper>
                PDF
              </DownloadLink>
            </DownloadItem>
          )}
          {shp && (
            <DownloadItem>
              <DownloadLink href={shp} download={shp}>
                <IconWrapper>
                  <DownloadSimple size={20} />
                </IconWrapper>
                SHP
              </DownloadLink>
            </DownloadItem>
          )}
          {gdp && (
            <DownloadItem>
              <DownloadLink href={gdp} download={gdp}>
                <IconWrapper>
                  <DownloadSimple size={20} />
                </IconWrapper>
                GDB
              </DownloadLink>
            </DownloadItem>
          )}
          {excel && (
            <DownloadItem>
              <DownloadLink href={`/static/${excel}`} download={title}>
                <IconWrapper>
                  <DownloadSimple size={20} />
                </IconWrapper>
                XLS
              </DownloadLink>
            </DownloadItem>
          )}
          {wmap && (
            <DownloadItem>
              <DownloadLink href={wmap} target="_blank">
                <IconWrapper>
                  <DownloadSimple size={20} />
                </IconWrapper>
                WEB
              </DownloadLink>
            </DownloadItem>
          )}
          {wms && (
            <DownloadItem>
              <DownloadLink href={wms}>
                <IconWrapper>
                  <DownloadSimple size={20} />
                </IconWrapper>
                WMS
              </DownloadLink>
            </DownloadItem>
          )}
          {kml && (
            <DownloadItem>
              <DownloadLink href={kml} download={kml}>
                <IconWrapper>
                  <DownloadSimple size={20} />
                </IconWrapper>
                KML
              </DownloadLink>
            </DownloadItem>
          )}
        </ContentPart>
      </DownloadLine>
    </DownloadSection>
  );
};

export default DownloadLinks;
