import SectionTitle from '../../../components/common/SectionTitle';
import ContentWrapper from '../../../components/ContentWrapper';
import styled from '@emotion/styled';
import { colors } from '../../../constants/colors';
import applicationImage from '../../../assets/platforms-min.png';
import screenSizes from '../../../constants/screenSizes';
import ApplicationItem from './ApplicationItem';
import { useEffect, useState } from 'react';
import axios from 'axios';

const defaultApplications = [
  {
    id: 2,
    platform_name: 'UN ASIGN',
    icon: '/static/unosat_filesystem/platforms/un_asign_logo.svg',
    link: 'https://asign.cern.ch/',
    category: 'Apps and Platforms',
  },
  {
    id: 3,
    platform_name: 'GDACS ',
    icon: '/static/unosat_filesystem/platforms/gdacs_logo_ecs_medium3.png',
    link: 'https://gdacs.org/',
    category: 'Apps and Platforms',
  },
  {
    id: 5,
    platform_name: 'Preventionweb',
    icon: '/static/unosat_filesystem/platforms/preventionweb_logo.png',
    link: 'https://www.preventionweb.net/',
    category: 'Other Distribution Channels',
  },
  {
    id: 6,
    platform_name: 'Reliefweb',
    icon: '/static/unosat_filesystem/platforms/RELIEFWEB_LOGO.png',
    link: 'https://reliefweb.int/',
    category: 'Other Distribution Channels',
  },
  {
    id: 4,
    platform_name: 'HDX',
    icon: '/static/unosat_filesystem/platforms/hdx_new_logo_accronym.png',
    link: 'https://data.humdata.org/',
    category: 'Other Distribution Channels',
  },
];

const categories = {
  UNOSAT: 'Apps and Platforms',
  OTHER: 'Other Distribution Channels',
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${screenSizes.large.min}) {
    flex-direction: row;
  }
`;

const Section = styled.section`
  padding-bottom: 80px;
  background-color: rgba(120, 141, 160, 1);

  @media (max-width: ${screenSizes.large.min}) {
    display: none;
  }
`;

const Description = styled.p`
  color: rgba(255, 255, 255, 0.5);
  margin-top: 10px;
  font-size: 28px;
  max-width: 80%;
  line-height: 44px;
  font-weight: 600;
`;

const PlatformsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSizes.large.min}) {
    flex-direction: row;
  }
`;

const PlatformSection = styled.div`
  flex: 1;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${screenSizes.medium.min}) {
    justify-content: flex-start;
  }
`;

const SectionTitleBig = styled.div`
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 20px;
  color: #fff;
`;

const ApplicationsSection = () => {
  const [applications, setApplications] = useState(null);

  useEffect(() => {
    axios
      .get('/our_platforms')
      .then((response) => {
        setApplications(response?.data);
      })
      .catch((error) => {
        console.log(error);
        setApplications(defaultApplications);
      });
  }, []);

  const categories = {
    UNOSAT: 'Apps and Platforms',
    OTHER: 'Other Distribution Channels',
  };

  return (
    <Section id="platforms">
      <ContentWrapper bgColor={'#788DA0'} customStyle={{ paddingTop: '80px' }}>
        <Header>
          <div>
            <SectionTitleBig>Platforms and Applications</SectionTitleBig>
            <Description>
              UNOSAT developed, in close collaboration with partners, different applications and
              platforms to provide more information and disseminate products further.
            </Description>
          </div>
        </Header>
      </ContentWrapper>
      {applications && (
        <PlatformsWrapper>
          <PlatformSection>
            <ContentWrapper bgColor={'#788DA0'}>
              <SectionTitle text="UNOSAT PLATFORMS & APPLICATIONS" hasLeftLine={false} />
              <ItemsWrapper>
                {applications
                  .filter((app) => app.category === categories.UNOSAT)
                  .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                  .map(({ platform_name, icon, link }, index) => (
                    <ApplicationItem
                      key={`application-unosat-${index}`}
                      platform_name={platform_name}
                      icon={icon}
                      link={link}
                      isGDACS={platform_name === 'GDACS '}
                    />
                  ))}
              </ItemsWrapper>
            </ContentWrapper>
          </PlatformSection>
          <PlatformSection>
            <ContentWrapper bgColor={'#788DA0'}>
              <SectionTitle text="OTHER DISTRIBUTION CHANNELS" hasLeftLine={false} />
              <ItemsWrapper>
                {applications
                  .filter((app) => app.category === categories.OTHER)
                  .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                  .map(({ platform_name, icon, link }, index) => (
                    <ApplicationItem
                      key={`application-other-${index}`}
                      platform_name={platform_name}
                      icon={icon}
                      link={link}
                    />
                  ))}
              </ItemsWrapper>
            </ContentWrapper>
          </PlatformSection>
        </PlatformsWrapper>
      )}
    </Section>
  );
};

export default ApplicationsSection;
