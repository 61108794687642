import React from 'react';
import styled from '@emotion/styled';
import { format, parseISO } from 'date-fns';
import { useHistory } from 'react-router';
import fallbackImage from '../../assets/map-photo.png';
import screenSizes from '../../constants/screenSizes';

const Card = styled.div`
  background-color: #e4eef6;
  box-shadow: 0 3px 9px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-top: 2px solid rgba(82, 94, 104, 1);
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  &:nth-of-type(even) {
    background-color: rgba(144, 165, 185, 0.3);
  }
`;

const ImageWrapper = styled.div`
  height: 200px;
  background-image: url('${({ imageUrl }) => imageUrl || fallbackImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  flex: 1;
`;

const Date = styled.div`
  font-size: 12px;
  color: rgba(82, 94, 104, 1);
  font-weight: 800;
  font-family: 'Inter', sans-serif;
  margin-bottom: 10px;
`;

const Location = styled.h2`
  font-size: 18px;
  font-weight: 700;
  color: rgba(82, 94, 104, 1);
  margin: 0 0 10px 0;
  font-family: 'Inter', sans-serif;
`;

const Title = styled.h3`
  font-size: 14px;
  color: rgba(82, 94, 104, 1);
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  line-height: 21px;
  margin: 0 0 20px 0;
`;

const SeeMoreButton = styled.button`
  align-self: flex-start;
  padding: 10px 20px;
  background-color: rgba(119, 139, 159, 1);
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #dee2e6;
    color: rgba(82, 94, 104, 0.9);
  }
`;

const MapCardItem = ({ data = {}, mapData = {} }) => {
  const history = useHistory();
  const { area_event_name } = data;
  const { id, title, image_file, created_at } = mapData;

  const handleSeeMore = (e) => {
    e.stopPropagation();

    history.push({
      pathname: `/products/${id}`,
      state: { id },
    });
  };

  return (
    <Card onClick={handleSeeMore}>
      <ImageWrapper imageUrl={image_file} />
      <TextContent>
        <div>
          <Date>{created_at && format(parseISO(created_at), 'dd MMMM yyyy')}</Date>
          <Location>{area_event_name}</Location>
          <Title>{title}</Title>
        </div>
        <SeeMoreButton onClick={handleSeeMore}>SEE MORE</SeeMoreButton>
      </TextContent>
    </Card>
  );
};

export default MapCardItem;
