import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { parseISO, format as formatDate } from 'date-fns';
import fallbackImage from '../../../assets/map-photo.png';
import DownloadLinks from './DownloadLinks';
import screenSizes from '../../../constants/screenSizes';

const PreviewContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const PreviewImage = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const PreviewTitle = styled.h2`
  font-size: 24px;
  color: rgba(82, 94, 104, 1);
  margin-bottom: 16px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 28px;
  }
`;

const PreviewDate = styled.div`
  font-size: 14px;
  color: rgba(82, 94, 104, 0.8);
  margin-bottom: 20px;
`;

const defaultProduct = {
  map_event: {
    id: 3816,
    title:
      'Preliminary satellite-derived flood assessment, Sava & Sofia Regions, Madagascar - 03 April 2024',
    description:
      'Status: Flooded agricultural areas and potentially affected structures observed...',
    image_file:
      'https://unosat-dev.app.cern.ch/static/unosat_filesystem/3816/UNOSAT_Preliminary_Assessment_Report_TC20240328MDG_Sava_SofiaRegions_03Apr2024.jpg',
    pdf_name: 'UNOSAT_Preliminary_Assessment_Report_TC20240328MDG_Sava_SofiaRegions_03Apr2024.pdf',
    excel_table: '/unosat_filesystem/3816/None',
    created_at: '2024-04-03T14:34:02.000045Z',
    wms_link: '',
    wmap_link:
      'https://experience.arcgis.com/experience/64da660b449f4324a6127bb4d5aada77/page/UNOSAT/?dlg=Information&draft=true&views=Layers',
    gdp_link: null,
    shp_link: null,
    kml_link: null,
  },
};

const ProductPreview = ({ productId }) => {
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    const IS_LOCALHOST = window.location.hostname === 'localhost';
    const fetchProductData = async () => {
      try {
        const response = await axios.get(`/our_products/${productId}`);
        setProductData(response.data.map_event);
      } catch (error) {
        console.error('Error fetching product details:', error);
        if (IS_LOCALHOST) {
          setProductData(defaultProduct.map_event);
        }
      }
    };

    fetchProductData();
  }, [productId]);

  if (!productData) {
    return <div>Loading...</div>;
  }

  const {
    title,
    created_at,
    image_file,
    id,
    pdf_name,
    excel_table,
    wmap_link,
    gdp_link,
    shp_link,
    kml_link,
    wms_link,
  } = productData;

  return (
    <PreviewContainer onClick={(e) => e.stopPropagation()}>
      <PreviewImage src={image_file || fallbackImage} alt="Product Preview" />
      <PreviewTitle>{title}</PreviewTitle>
      <PreviewDate>{formatDate(parseISO(created_at), 'dd MMM yyyy')}</PreviewDate>
      <DownloadLinks
        title={title}
        pdfName={pdf_name !== 'None' ? pdf_name : undefined}
        excel={excel_table.includes('/None') ? undefined : excel_table}
        wmap={wmap_link}
        gdp={gdp_link}
        shp={shp_link}
        wms={wms_link}
        kml={kml_link}
        id={id}
      />
    </PreviewContainer>
  );
};

export default ProductPreview;
