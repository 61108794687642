import { Warning, CheckCircle, Info, X } from 'phosphor-react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '../../constants/colors';

const notificationVariants = {
  confirmation: css`
    background-color: #e9f5ff;
    border: 1px solid #3586d1;
  `,
  warning: css`
    background: #fcf3d8;
    border: 1px solid #ec8a0b;
  `,
};

const variantStyles = (variant) => notificationVariants[variant];

const getIcon = (variant) => {
  const size = 20;
  const weight = 'fill';
  switch (variant) {
    case 'confirmation':
      return <CheckCircle color="#3586d1" size={size} weight={weight} />;
    case 'warning':
      return <Warning color="#ec8a0b" size={size} weight={weight} />;
    default:
      return <Info color={colors.BLUE} size={size} weight={weight} />;
  }
};

const Wrapper = styled.div`
  line-height: 1.5;
  position: relative;

  ${({ variant }) => variantStyles(variant)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 16px 32px 16px 16px;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 16px;
    font-weight: 400;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: 12px;
  margin-top: 1px;
`;

const StyledButton = styled.button`
  height: auto;
  appearance: none;
  border: none;
  padding: 5px;
  position: absolute;
  right: 3px;
  top: 3px;
  background: transparent;
  cursor: pointer;

  & svg {
    height: 20px;
    width: 20px;
  }

  & span {
    display: flex;
  }
`;

const Notification = ({ children, title, variant = 'confirmation', onClose }) => (
  <Wrapper variant={variant}>
    <ContentWrapper>
      <IconWrapper>{getIcon(variant)}</IconWrapper>
      <Content>
        {title && <h2>{title}</h2>}
        <div>{children}</div>
      </Content>
      {onClose && (
        <StyledButton aria-label="Close" onClick={onClose} type="button">
          <X size={24} color="#333" weight="thin" />
        </StyledButton>
      )}
    </ContentWrapper>
  </Wrapper>
);

export default Notification;
