import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import { colors } from '../../constants/colors';
import { useHistory } from 'react-router';
import { routes } from '../../constants/routes';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  cursor: pointer;
  background: rgba(73, 73, 73, 0.1);
  border-radius: 12px;
  width: calc(50% - 10px);
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  ${({ isSelected }) => isSelected && `border: 3px solid ${colors.GRAY};`};

  @media (min-width: ${screenSizes.medium.min}) {
    width: calc(33.33% - 13.33px);
  }

    &:hover {
    transform: translateY(-5px);
    background-color: rgba(73, 73, 73, 0.2);
  }

  @media (min-width: ${screenSizes.large.min}) {
    width: calc(25% - 15px);
    max-width: 340px;
  }
`;

const Image = styled.img`
  height: 70px;
  width: 70px;
  margin-bottom: 10px;

  @media (min-width: ${screenSizes.small.min}) {
    height: 100px;
    width: 100px;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 900;
  margin-top: 10px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 18px;
  }
`;

const ServiceCardItem = ({ icon, title, selectedService }) => {
  const history = useHistory();

  const handleOnClick = () => {
    const device = window.innerWidth <= 479 ? 'mobile' : 'desktop';
    const offset = device === 'mobile' ? 300 : 200;

    history.push(routes.SERVICES, { selectedService: title });

    // Attendez que le composant soit monté et rendu
    setTimeout(() => {
      const serviceElement = document.getElementById('service');
      if (serviceElement) {
        const y = serviceElement.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 100); // Petit délai pour s'assurer que le composant est rendu
  };

  const isSelected = selectedService === title;

  return (
    <Item onClick={handleOnClick} isSelected={isSelected}>
      <Image src={icon} alt={title} />
      <Title>{title}</Title>
    </Item>
  );
};

export default ServiceCardItem;
