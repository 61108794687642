import React from 'react';
import styled from '@emotion/styled';
import { format, parseISO } from 'date-fns';
import { useHistory } from 'react-router';
import LinkButtons from './product/DownloadLinksNew';

const IS_LOCALHOST = window.location.hostname === 'localhost';

const Card = styled.div`
  display: flex;
  overflow: hidden;
  border-top: 2px solid rgba(82, 94, 104, 1);
  background-color: #e4eef6;

  &:nth-of-type(even) {
    background-color: rgba(144, 165, 185, 0.3);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100%;
    text-align: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftColumn = styled(Column)`
  width: 22%;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 15px;
  }
`;

const CenterColumn = styled(Column)`
  width: 22%;
  margin-right: 2%;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    align-items: center;
  }
`;

const ColDownloadItem = styled(Column)`
  width: 22%;
  margin-left: 10%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Location = styled.h2`
  font-size: 25px;
  font-weight: 700;
  color: rgba(82, 94, 104, 1);
  margin: 0 0 15px 0;
  font-family: 'Inter', sans-serif;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const Date = styled.div`
  font-size: 12px;
  color: rgba(82, 94, 104, 1);
  font-weight: 800;
  font-family: 'Inter', sans-serif;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 8px;
  }
`;

const Title = styled.h3`
  font-size: 14px;
  color: rgba(82, 94, 104, 1);
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  line-height: 21px;
  margin: 0 0 20px 0;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
`;

const SeeMoreButton = styled.button`
  align-self: flex-start;
  padding: 10px 20px;
  background-color: rgba(119, 139, 159, 1);
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #dee2e6;
    color: rgba(82, 94, 104, 0.9);
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 12px 20px;
    font-size: 14px;
    margin-top: 10px;
  }
`;

const ImageContainer = styled.div`
  max-width: 500px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  max-height: 300px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const ImageWrapper = ({ imageUrl, onClick }) => (
  <ImageContainer>
    <StyledImage
      src={
        IS_LOCALHOST
          ? 'https://unosat-dev.app.cern.ch/static/unosat_filesystem/3816/UNOSAT_Preliminary_Assessment_Report_TC20240328MDG_Sava_SofiaRegions_03Apr2024.jpg'
          : imageUrl ||
            'https://unosat-dev.app.cern.ch/static/unosat_filesystem/3816/UNOSAT_Preliminary_Assessment_Report_TC20240328MDG_Sava_SofiaRegions_03Apr2024.jpg'
      }
      alt="Product image"
      onClick={onClick}
    />
  </ImageContainer>
);

const ResultItem = ({ data = {}, mapData = {}, onClick = () => {} }) => {
  const history = useHistory();
  const { area_event_name } = data;
  const {
    id,
    title,
    image_file,
    created_at,
    wmap_link,
    gdp_link,
    shp_link,
    kml_link,
    wms_link,
    pdf_name,
    excel_table,
  } = mapData;

  const handleSeeMore = () => {
    history.push({
      pathname: `/products/${id}`,
      state: { id },
    });
  };

  const handleImageClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <Card>
      <ContentWrapper>
        <CenterColumn>
          <Date>{created_at && format(parseISO(created_at), 'dd MMMM yyyy')}</Date>
          <Location>{area_event_name}</Location>
          <Title>{title}</Title>
        </CenterColumn>
        <LeftColumn>
          <SeeMoreButton onClick={handleSeeMore}>SEE MORE</SeeMoreButton>
        </LeftColumn>
        <ColDownloadItem>
          <LinkButtons
            title={title}
            pdfName={pdf_name !== 'None' ? pdf_name : undefined}
            excel={excel_table && !excel_table.includes('/None') ? excel_table : undefined}
            wmap={wmap_link}
            gdp={gdp_link}
            shp={shp_link}
            wms={wms_link}
            kml={kml_link}
            id={id}
          />
        </ColDownloadItem>
        <ImageWrapper imageUrl={image_file} onClick={handleImageClick} />
      </ContentWrapper>
    </Card>
  );
};

export default ResultItem;
