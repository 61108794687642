import { MapPin } from 'phosphor-react';
import styled from '@emotion/styled';
import { colors } from '../../constants/colors';
import formatDate from 'date-fns/format';
import { parseISO } from 'date-fns';
import { useHistory } from 'react-router';

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Date = styled.div``;

const Place = styled.div`
  display: flex;
  color: #617077;
  align-items: center;
  margin: 8px 0;
`;

const City = styled.div`
  margin-left: 5px;
  font-weight: 600;
`;

const Title = styled.div`
  margin: 5px 0;
`;

const SeeMoreButton = styled.button`
  background-color: ${colors.BLUE};
  color: #fff;
  cursor: pointer;
  outline: none;
  height: auto;
  border: none;
  align-self: flex-start;
  padding: 5px 10px;
  border-radius: 3px;
  margin: 5px 0;
`;

const PopupMapItem = ({ title, created_at, id, area_event_name, extraData }) => {
  const history = useHistory();

  const handleOnClick = () => {
    history.push({
      pathname: `/products/${id}`,
      state: { id, extraData },
    });
  };

  return (
    <Content>
      <Date>{created_at && formatDate(parseISO(created_at), 'dd MMM yyyy')}</Date>
      <Place>
        <MapPin size={22} weight="fill" color="#617077" />
        <City>{area_event_name}</City>
      </Place>
      <Title>{title}</Title>
      <SeeMoreButton onClick={handleOnClick}>See more</SeeMoreButton>
    </Content>
  );
};

export default PopupMapItem;
