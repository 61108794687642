import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as UnitarUnosatLogo } from '../../assets/UNITAR_UNOSAT_Vertical_White_Version.svg';
import { ReactComponent as UnitarUnosatLogoMobile } from '../../assets/LOGO_Vertical_White_Version_2.svg';
import screenSizes from '../../constants/screenSizes';

const LogoWrapper = styled(Link)`
  flex: 0 0 auto;
  cursor: pointer;
  text-decoration: none;
`;

const LogoSvg = styled.div`
  width: 80px;

  @media (min-width: ${screenSizes.medium.min}) {
    width: 130px;
  }

  & svg {
    fill: #ffffff;
  }
`;

const DesktopLogo = styled(UnitarUnosatLogo)`
  display: none;

  @media (min-width: ${screenSizes.medium.min}) {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const MobileLogo = styled(UnitarUnosatLogoMobile)`
  display: block;
  width: 39px;
  height: 39px;

  @media (min-width: ${screenSizes.medium.min}) {
    display: none;
  }
`;

const Logo = ({ to }) => (
  <LogoWrapper to={to}>
    <LogoSvg>
      <DesktopLogo />
      <MobileLogo />
    </LogoSvg>
  </LogoWrapper>
);

export default Logo;
