import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';
import { XSquare } from 'phosphor-react';

const Header = styled.header`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.GRAY};
`;

const Title = styled.h2`
  color: ${colors.BLACK};
  margin: 0 auto;
  font-weight: 600;
`;

const Content = styled('div')`
  line-height: 1.3;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 30px;
  height: 70%;

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 14px;
    padding: 20px 30px;
  }
`;

const Footer = styled('footer')`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 10px 20px;
  position: relative;

  &::before {
    background-image: linear-gradient(rgba(255, 255, 255, 0), #fff 80%);
    border-radius: 3px;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    right: 0;
    top: -20px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 10px 20px 20px;
  }
`;

const CloseButton = styled('button')`
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 10px;
  z-index: 2;
`;

const ModalDecorator = ({ className, ...props }) => {
  const [name] = (className && className.split(' ')) || [''];
  const styles = name
    ? {
        portalClassName: name,
        overlayClassName: `${name}__Overlay`,
        className: `${name}__Content`,
      }
    : {};

  return <ReactModal {...styles} {...props} />;
};

const ModalCloseButton = ({ dataTestId, hasImage, onClick }) => (
  <CloseButton data-testid={dataTestId} hasImage={hasImage} onClick={onClick} type="button">
    <XSquare size={34} />
  </CloseButton>
);

const StyledModal = styled(ModalDecorator)`
  &__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
  }

  &__Content {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    padding: 10px;
    inset: 1%;
    margin-top: 80px;
    z-index: 999;
  }

  @media (min-width: ${screenSizes.medium.min}) {
    &__Content {
      padding: 20px;
      inset: 10%;
      margin-top: 0;
    }
  }

  @media (min-width: ${screenSizes.large.min}) {
    &__Content {
      inset: 15%;
    }
  }
`;

const Modal = ({
  appElement = document.querySelector('#root'),
  children,
  contentClassName,
  footer,
  header,
  onClose,
  onRequestClose,
  isOpen,
  title,
}) =>
  isOpen ? (
    <StyledModal
      appElement={appElement}
      contentLabel={title}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      <Header>
        {header}
        {onClose && <ModalCloseButton onClick={onClose} />}
        {title && (
          <Title className="etiModal__title" size={3}>
            {title}
          </Title>
        )}
      </Header>
      {children && (
        <Content hasTitle={Boolean(title)} className={contentClassName}>
          {children}
        </Content>
      )}
      {footer && <Footer className="footer">{footer}</Footer>}
    </StyledModal>
  ) : null;

export default Modal;
