import SwitchButton from './SwitchButton';
import Select, { createFilter } from 'react-select';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import styled from '@emotion/styled';
import { colors } from '../../../constants/colors';
import screenSizes from '../../../constants/screenSizes';
import { useEffect, useRef, useState } from 'react';
import { MagnifyingGlass } from 'phosphor-react';
import formatDate from 'date-fns/format';
import isValid from 'date-fns/isValid';
import Checkbox from './Checkbox';
import { getMonth, getYear } from 'date-fns';

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 30px 0 0;

  & div {
    margin-left: 10px;
  }

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
  }
`;

const SearchField = styled.div`
  background-color: #f4f6f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  width: 95%;

  @media (min-width: ${screenSizes.medium.min}) {
    width: 40%;
  }
`;

const Input = styled.input`
  background-color: #f4f6f6;
  border: 0;
  outline: none;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FilterTitle = styled.h3`
  font-size: 16px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 45%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  border-bottom: 2px solid ${colors.GRAY};
  min-height: 120px;
  justify-content: space-between;

  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: row;
    align-items: center;
    min-height: unset;
  }
`;

const CharterFilter = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 30px 0 0;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
  }
`;

const CharterLabel = styled.div`
  margin-right: 10px;
`;

const DateInput = styled.input`
  background-color: #f4f6f6;
  padding: 15px;
  border-radius: 5px;
  border: none;
  width: 90%;
`;

const DateField = styled.div`
  position: relative;
  flex: 0 0 45%;
`;

const CalendarWrapper = styled.div`
  z-index: 10;
  position: absolute;
  background-color: #fff;
  top: 70px;
  padding: 20px;
  ${({ isRight }) => isRight && 'right: 0;'}
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
`;

const DatesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  margin-left: 2px;
`;

const CalendarDayPicker = styled(DayPicker)`
  button.rdp-day_selected {
    background-color: ${colors.BLUE};
  }
`;

const Filters = ({ countries, activationTypes, setSelectedFilters, selectedFilters }) => {
  const datesRef = useRef(null);
  const [isFromCalendarVisible, setIsFromCalendarVisible] = useState(false);
  const [isToCalendarVisible, setIsToCalendarVisible] = useState(false);
  const [inputFromValue, setInputFromValue] = useState(
    selectedFilters?.date_from && formatDate(Date.parse(selectedFilters.date_from), 'dd-MM-yyyy'),
  );
  const [inputToValue, setInputToValue] = useState(
    selectedFilters?.date_to && formatDate(Date.parse(selectedFilters.date_to), 'dd-MM-yyyy'),
  );
  const [fromDateSelected, setFromDateSelected] = useState(new Date(selectedFilters.date_from));
  const [toDateSelected, setToDateSelected] = useState(new Date(selectedFilters.date_to));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datesRef.current && !datesRef.current.contains(event.target)) {
        setIsFromCalendarVisible(false);
        setIsToCalendarVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datesRef]);

  const transformedCountriesValues = countries?.reduce((acc, country) => {
    return [...acc, { value: country, label: country }];
  }, []);

  const transformedActivationTypes = activationTypes?.reduce((acc, activationType) => {
    return [...acc, { value: activationType, label: activationType }];
  }, []);

  const handleCountriesChange = (values) => {
    setSelectedFilters({
      ...selectedFilters,
      region: values,
    });

    console.log('CHECK : ', setSelectedFilters);
  };

  const handleActivationTypesChange = (values) => {
    setSelectedFilters({
      ...selectedFilters,
      activation_type: values,
    });
  };

  const handleOnTitleChange = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      title: event.target.value,
    });
  };

  const handleFromInputOnFocus = () => {
    setIsFromCalendarVisible(true);
    setIsToCalendarVisible(false);
  };

  const handleToInputOnFocus = () => {
    setIsToCalendarVisible(true);
    setIsFromCalendarVisible(false);
  };

  const handleFromDaySelect = (date) => {
    setFromDateSelected(date);

    const date_from = isValid(date) && formatDate(date, 'yyyy-MM-dd');

    if (date) {
      setInputFromValue(formatDate(date, 'dd-MM-yyyy'));
      setIsFromCalendarVisible(false);
      setSelectedFilters({
        ...selectedFilters,
        date_from,
      });
    } else {
      setInputFromValue('');
    }
  };

  const handleToDaySelect = (date) => {
    setToDateSelected(date);

    const date_to = isValid(date) && formatDate(date, 'yyyy-MM-dd');

    if (date) {
      setInputToValue(formatDate(date, 'dd-MM-yyyy'));
      setIsToCalendarVisible(false);
      setSelectedFilters({
        ...selectedFilters,
        date_to,
      });
    } else {
      setInputToValue('');
    }
  };

  const today = new Date();

  return (
    <>
      <Filter>
        <FilterTitle>FILTER BY TEXT</FilterTitle>
        <SearchField>
          <MagnifyingGlass size={20} color="#909DA2" />
          <Input
            type="text"
            id="text-search"
            placeholder="Search title"
            onChange={handleOnTitleChange}
            value={selectedFilters?.title}
          />
        </SearchField>
      </Filter>
      <Filter>
        <FilterTitle>FILTER BY DATE</FilterTitle>
        <DatesWrapper ref={datesRef}>
          <DateField>
            <Label>From</Label>
            <DateInput
              autoComplete="off"
              onFocus={handleFromInputOnFocus}
              placeholder="From"
              value={inputFromValue}
            />
            {isFromCalendarVisible && (
              <CalendarWrapper>
                <CalendarDayPicker
                  mode="single"
                  defaultMonth={
                    new Date(
                      getYear(Date.parse(fromDateSelected)),
                      getMonth(Date.parse(fromDateSelected)),
                    )
                  }
                  onDayClick={handleFromDaySelect}
                  selected={fromDateSelected}
                  disabled={[{ after: today }, { after: toDateSelected }]}
                />
              </CalendarWrapper>
            )}
          </DateField>
          <DateField>
            <Label>To</Label>
            <DateInput
              autoComplete="off"
              onFocus={handleToInputOnFocus}
              placeholder="To"
              value={inputToValue}
            />
            {isToCalendarVisible && (
              <CalendarWrapper isRight>
                <CalendarDayPicker
                  mode="single"
                  defaultMonth={
                    new Date(
                      getYear(Date.parse(toDateSelected)),
                      getMonth(Date.parse(toDateSelected)),
                    )
                  }
                  onDayClick={handleToDaySelect}
                  selected={toDateSelected}
                  disabled={[{ before: fromDateSelected }, { after: today }]}
                />
              </CalendarWrapper>
            )}
          </DateField>
        </DatesWrapper>
      </Filter>

      <Wrapper>
        <FilterWrapper>
          <FilterTitle>FILTER BY COUNTRY</FilterTitle>
          <Select
            options={transformedCountriesValues}
            isMulti
            filterOption={createFilter({ matchFrom: 'start' })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleCountriesChange}
            value={selectedFilters?.region}
          />
        </FilterWrapper>
        <FilterWrapper>
          <FilterTitle>FILTER BY ACTIVATION TYPE</FilterTitle>
          <Select
            options={transformedActivationTypes}
            isMulti
            filterOption={createFilter({ matchFrom: 'start' })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleActivationTypesChange}
            value={selectedFilters?.activation_type}
          />
        </FilterWrapper>
      </Wrapper>

      <CharterFilter>
        <CharterLabel>International Charter Space and Major Disasters activated</CharterLabel>
        <Checkbox
          id="charter-activation"
          isChecked={false}
          name="charter-activation"
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
        />
      </CharterFilter>

      <SliderWrapper>
        <SwitchButton
          id="is_active"
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
        />
        <div>Include closed projects</div>
      </SliderWrapper>
    </>
  );
};

export default Filters;
