export const defaultProducts = {
  products: [
    {
      map_event: {
        id: 3816,
        title:
          'Preliminary satellite-derived flood assessment, Sava & Sofia Regions, Madagascar - 03 April 2024',
        image_file:
          '/static/unosat_filesystem/3816/UNOSAT_Preliminary_Assessment_Report_TC20240328MDG_Sava_SofiaRegions_03Apr2024.jpg',
        glide: 'TC20240328MDG',
        created_at: '2024-04-03T14:34:02.000045Z',
      },
      longitude: 49.230526999999995,
      latitude: -14.03321,
      area_event_name: 'Madagascar',
      activation_type: 'Tropical Cyclone',
    },
    {
      map_event: {
        id: 3815,
        title: 'UNOSAT Live Web Map - Tropical Cyclone Gamane-24 Madagascar',
        image_file: '/static/unosat_filesystem/3815/UNOSAT_LiveWebMap_TC20240328MDG.png',
        glide: 'TC20240328MDG',
        created_at: '2024-04-03T07:59:37.369931Z',
      },
      longitude: 48.407078,
      latitude: -13.9592785,
      area_event_name: 'Madagascar',
      activation_type: 'Tropical Cyclone',
    },
    {
      map_event: {
        id: 3814,
        title:
          'Satellite detected water extent over Jawa Tengah Province, Indonesia as of 27 March 2024',
        image_file:
          '/static/unosat_filesystem/3814/UNOSAT_GISTDA_A3_Natural_Landscape_FL20240326IDN_Map2_Optimized%20(1)_1.jpg',
        glide: 'EQ20240326IDN',
        created_at: '2024-03-29T07:44:58.392057Z',
      },
      longitude: 110.8869765,
      latitude: -6.7173135,
      area_event_name: 'Indonesia',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3813,
        title:
          'Satellite detected water extent over Maputo province, Mozambique as of 27 March 2024',
        image_file:
          '/static/unosat_filesystem/3813/UNOSAT_A3_Natural_Portrait_TC20240313MOZ_Maputo_Map3_1.jpg',
        glide: 'TC20240313MOZ',
        created_at: '2024-03-29T06:41:29.877266Z',
      },
      longitude: 32.4821205,
      latitude: -26.06507965,
      area_event_name: 'Mozambique',
      activation_type: 'Tropical Cyclone',
    },
    {
      map_event: {
        id: 3812,
        title:
          'Preliminary satellite-derived damage assessment (Indonesia earthquake, M 6.4) - 27 March 2024',
        image_file:
          '/static/unosat_filesystem/3812/UNOSAT_Preliminary_Assessment_Report_EQ20240326IDN_BeweanIsland_Indonesia_27MARCH2024.jpg',
        glide: 'EQ20240326IDN',
        created_at: '2024-03-27T14:57:33.051104Z',
      },
      longitude: 112.663277,
      latitude: -5.7915275,
      area_event_name: 'Indonesia',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3811,
        title:
          'Satellite detected water extent over Maputo province, Mozambique, as of 23 March 2024',
        image_file: '/static/unosat_filesystem/3811/Screenshot%202024-03-27%20142059.png',
        glide: 'TC20240313MOZ',
        created_at: '2024-03-27T10:38:04.674198Z',
      },
      longitude: 32.4821205,
      latitude: -26.06507965,
      area_event_name: 'Mozambique',
      activation_type: 'Tropical Cyclone',
    },
    {
      map_event: {
        id: 3810,
        title:
          'Satellite detected water extent over Jawa Tengah Province, Indonesia as of 19 Mar. 2024',
        image_file:
          '/static/unosat_filesystem/3810/UNOSAT_A3_Natural_Landscape_FL20240326IDN_Map1_3.jpg',
        glide: 'EQ20240326IDN',
        created_at: '2024-03-26T13:19:54.890021Z',
      },
      longitude: 110.8869765,
      latitude: -6.7173135,
      area_event_name: 'Indonesia',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3809,
        title:
          'Preliminary Satellite-derived Flood Assessment Report, Maputo City, Mozambique, 23 March 2023',
        image_file: '/static/unosat_filesystem/3809/Screenshot%202024-03-26%20105318.png',
        glide: 'TC20240313MOZ',
        created_at: '2024-03-26T10:00:49.319493Z',
      },
      longitude: 32.56507299148278,
      latitude: -25.866630901240356,
      area_event_name: 'Mozambique',
      activation_type: 'Tropical Cyclone',
    },
    {
      map_event: {
        id: 3808,
        title:
          'Satellite detected water extent over Gaza and Maputo provinces, Mozambique, as of 16 Mar. 2024',
        image_file:
          '/static/unosat_filesystem/3808/UNOSAT_A3_Portrait_TC20240313MOZ_Maputo_Gaza_Mozambique_20240316.png',
        glide: 'TC20240313MOZ',
        created_at: '2024-03-21T15:28:21.705319Z',
      },
      longitude: 32.96230385,
      latitude: -24.846553550000003,
      area_event_name: 'Mozambique',
      activation_type: 'Tropical Cyclone',
    },
    {
      map_event: {
        id: 3804,
        title:
          'UNOSAT Gaza Strip Comprehensive Building & Housing Unit Damage Assessment - March 2024',
        image_file: '/static/unosat_filesystem/3804/UNOSAT_A3_GazaStrip_CDA_March2024.png',
        glide: 'CE20231007PSE',
        created_at: '2024-03-20T13:49:12.187549Z',
      },
      longitude: 34.523283,
      latitude: 31.5620565,
      area_event_name: 'Occupied Palestinian Territory',
      activation_type: 'Complex Emergency',
    },
    {
      map_event: {
        id: 3803,
        title:
          'Preliminary Satellite-derived Flood Impact Assessment, Maputo City, Praia De Bilene in Gaza Province and Cidade De Inhambane, Mozambique, 18 March 2024',
        image_file: '/static/unosat_filesystem/3803/Screenshot%202024-03-18%20203848.png',
        glide: 'TC20240313MOZ',
        created_at: '2024-03-18T19:46:52.027007Z',
      },
      longitude: 33.85500456157318,
      latitude: -24.92005528937837,
      area_event_name: 'Mozambique',
      activation_type: 'Tropical Cyclone',
    },
    {
      map_event: {
        id: 3802,
        title:
          'Preliminary Satellite-derived Flood Impact Assessment, Vilankulos Town, Vilankulo Disrict, Inhambane Province  and Beira City, Cidade Da Beira District, Sofala Province Mozambique, 18 March 2024',
        image_file:
          '/static/unosat_filesystem/3802/UNOSAT_Preliminary_Assessment_Report_TC20240313_Beira_Vilankulo_18Mar2024_1.jpg',
        glide: 'TC20240313MOZ',
        created_at: '2024-03-18T10:01:12.206122Z',
      },
      longitude: 35.069348725811594,
      latitude: -20.93259699650073,
      area_event_name: 'Mozambique',
      activation_type: 'Tropical Cyclone',
    },
    {
      map_event: {
        id: 3801,
        title: 'UNOSAT Live Web Map - Tropical Cyclone Filipo-24 Mozambique',
        image_file: '/static/unosat_filesystem/3801/Screenshot%202024-03-15%20214903.png',
        glide: 'TC20240313MOZ',
        created_at: '2024-03-14T11:29:15.369405Z',
      },
      longitude: 33.5527425,
      latitude: -21.9355,
      area_event_name: 'Mozambique',
      activation_type: 'Tropical Cyclone',
    },
    {
      map_event: {
        id: 3793,
        title: 'UNOSAT Gaza Strip Comprehensive Damage Assessment - January 2024',
        image_file: '/static/unosat_filesystem/3793/UNOSAT_A3_GazaStrip_CDA_January2024_v2.png',
        glide: 'CE20231007PSE',
        created_at: '2024-02-01T16:06:07.244007Z',
      },
      longitude: 34.523283,
      latitude: 31.5620565,
      area_event_name: 'Occupied Palestinian Territory',
      activation_type: 'Complex Emergency',
    },
    {
      map_event: {
        id: 3792,
        title: 'UNOSAT Gaza Strip Agricultural Damage Assessment - January 2024',
        image_file: '/static/unosat_filesystem/3792/UNOSAT_A3_GazaStrip_ADA_January2024.png',
        glide: 'CE20231007PSE',
        created_at: '2024-02-01T15:58:52.348403Z',
      },
      longitude: 34.523283,
      latitude: 31.5620565,
      area_event_name: 'Occupied Palestinian Territory',
      activation_type: 'Complex Emergency',
    },
  ],
  countries: [
    'Bangladesh',
    'Indonesia',
    'Cameroon',
    'Western Africa',
    'Occupied Palestinian Territory',
    'Viet Nam',
    'Uganda',
    'Jordan',
    'Cambodia',
    'Papua New Guinea',
    'American Samoa',
    'Sri Lanka',
    'Korea (the Republic of)',
    'Colombia',
    "Côte D'Ivoire",
    'Albania',
    'Ukraine',
    'Grenada',
    'Sao Tome and Principe',
    'Solomon Islands (the)',
    'Cuba',
    'Kyrgyzstan',
    'Algeria',
    'Tanzania, United Republic of',
    'Cape Verde',
    'Maldives',
    'Israel',
    'Djibouti',
    'Senegal',
    'Kenya',
    'Ghana',
    'Zambia',
    'Kuwait',
    'Madagascar',
    'Sierra Leone',
    'Bosnia and Herzegovina',
    'Liberia',
    'Sudan (the)',
    'Benin',
    'The Caribbean',
    'Guinea',
    'Turkey',
    "Lao People's Democratic Republic (the)",
    'Nigeria',
    'Rwanda',
    'Netherlands (the)',
    'Zimbabwe',
    'China',
    'Comoros',
    'Marshall Islands (the)',
    'Armenia',
    'Paraguay',
    'Dominica',
    'Mauritius',
    'Serbia',
    'Bolivia, Plurinational State of',
    'Dominican Republic (the)',
    'Angola',
    'Libya',
    'Southern Africa',
    'Vanuatu',
    'Moldova (the Republic of)',
    'Iran (the Islamic Republic of)',
    'Congo',
    'Autonomous Province of Kosovo',
    'Georgia',
    'Malawi',
    'East Europe',
    'Micronesia (the Federated States of)',
    'Burundi',
    'Central African Republic (the)',
    'South East Asia',
    'Central America',
    'Thailand',
    'Congo (the Democratic Republic of the)',
    'Togo',
    'Philippines (the)',
    'Burkina Faso',
    'El Salvador',
    'Uruguay',
    'Fiji',
    'Barbados',
    'Russian Federation (the)',
    'Niger (the)',
    'World',
    'Saint Vincent and the Grenadines',
    'Argentina',
    'Namibia',
    'Greece',
    'Afghanistan',
    'Chad',
    'India',
    'Timor-Leste',
    'Chile',
    'Macedonia (the former Yugoslav Republic of)',
    'South Africa',
    'Suriname',
    'Peru',
    'Kazakhstan',
    'Guadeloupe',
    'Japan',
    'Jamaica',
    'Iraq',
    'Mauritania',
    'Mozambique',
    'Seychelles',
    'Switzerland',
    'Ecuador',
    'Honduras',
    'Norway',
    'Belize',
    'Pakistan',
    'Brazil',
    'Guatemala',
    'Ethiopia',
    'West Bank',
    'Panama',
    'South Sudan',
    'Yemen',
    'Tunisia',
    'Mali',
    'Syrian Arab Republic (the)',
    'Morocco',
    'Horn of Africa',
    'Myanmar',
    'Mexico',
    'Nicaragua',
    'Tonga',
    'Guyana',
    'Nepal',
    'Gambia (The)',
    'Lebanon',
    'Costa Rica',
    'Haiti',
    'Equatorial Guinea',
    'Samoa',
    'Antigua and Barbuda',
    'Somalia',
  ],
  activation_types: [
    'Cold Wave',
    'Complex Emergency',
    'Drought',
    'Earthquake',
    'Environmental Impact',
    'Epidemic',
    'Exercise / Simulation',
    'Extratropical Cyclone',
    'Extreme Temperature',
    'Famine',
    'Fire',
    'Flash Flood',
    'Floods',
    'Heat Wave',
    'Insect Infestation',
    'Landslides',
    'Mud Slide',
    'No glide',
    'Oil Spill',
    'Other',
    'Severe Local Storm',
    'Snow Avalanche',
    'Storm Surge',
    'Tech. Disaster',
    'Tornadoes',
    'Tropical Cyclone',
    'Tsunami',
    'Violent  Wind',
    'Volcano',
    'Wild Fire',
    'None',
    'None',
  ],
};
