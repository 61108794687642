import styled from '@emotion/styled';
import { colors } from '../../../constants/colors';

const ItemWrapper = styled.div`
  margin: 12px;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  background-color: rgba(73, 73, 73, 0.1);
`;

const Logo = styled.img`
  object-fit: contain;
  width: 100px;
  height: 100px;
`;

const Text = styled.div`
  color: #fff;
  margin-top: 10px;
  font-size: 19px;
  font-weight: 900;
  text-align: left;
`;

const ApplicationItem = ({ platform_name, icon, link, isGDACS }) => {
  const handleOnClick = () => {
    window.open(link, '_blank');
  };

  return (
    <div>
      <ItemWrapper onClick={handleOnClick}>
        <Logo
          src={
            // 'https://unosat-dev.app.cern.ch/static/unosat_filesystem/platforms/hdx_new_logo_accronym.png'
            icon
          }
          isGDACS={isGDACS}
        />
      </ItemWrapper>
      <Text>{platform_name.toUpperCase()}</Text>
    </div>
  );
};

export default ApplicationItem;
