import styled from '@emotion/styled';
import screenSizes from '../constants/screenSizes';

const Wrapper = styled.div`
  ${({ bgColor }) => `background-color: ${bgColor}`};
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: space-between;
  @media (min-width: ${screenSizes.medium.min}) {
    padding: 20px 80px;
  }
`;

const ContentWrapper = ({
  children,
  customRef,
  bgColor,
  direction = 'column',
  customStyle = '',
  ...rest
}) => (
  <Wrapper
    ref={customRef}
    {...rest}
    bgColor={bgColor}
    direction={direction}
    style={{ ...customStyle }}
  >
    {children}
  </Wrapper>
);

export default ContentWrapper;
