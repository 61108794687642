import React, { useState } from 'react';
import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import HomeHeader from '../../components/site-header/HomeHeader';

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  background: rgba(144, 165, 185, 1);
`;

const Content = styled.div`
  padding: 120px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(144, 165, 185, 1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  @media (min-width: ${screenSizes.medium.min}) {
    padding: 120px 20px 80px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 200px 80px 100px;
  }

`;

const Description = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  line-height: 1.6;
  margin-top: 20px;
  width: 100%;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 24px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 28px;
    line-height: 1.3;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 0 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 0 0px;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  width: 100%;
  font-weight: 900;
  color: white;
  text-align: left;
  margin: 0;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 48px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 56px;
  }
`;

const ProjectList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0px 100px;
`;

const ProjectItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.index % 2 === 0 ? 'rgba(144, 165, 185, 1)' : '#7f94a8')};
  overflow: hidden;
  border-top: 2px solid rgba(255, 255, 255, 1);
  padding: 16px;

  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: row;
    align-items: center;
    padding: 20px 10px;
  }
`;

const ProjectImage = styled.div`
  width: 100%;
  height: 200px;
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);

  @media (min-width: ${screenSizes.medium.min}) {
    width: 300px;
    height: 200px;
    min-width: 300px;
    margin-top: 0;
    margin-left: 20px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const ProjectInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ProjectTitle = styled.h3`
  color: white;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 25px;
    margin-bottom: 10px;
  }
`;

const ProjectDate = styled.p`
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  margin-bottom: 8px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
`;

const SeeMoreButton = styled.button`
  background: rgba(105, 120, 135, 1);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 16px;
  align-self: flex-start;
  transition: background-color 0.3s;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 12px;

  &:hover {
    background-color: rgba(105, 120, 135, 0.8);
  }

  a {
    color: white;
    text-decoration: none;
  }
`;

const ProjectNew = () => {
  const projects = [
    {
      title:
        'Strengthening Capacities in the use of geospatial information for improved resilience in Asia-Pacific and Africa',
      date: 'DD Month YYYY',
      image: 'https://unitar.org/sites/default/files/media/image/UNOSAT-NORAD_partnership.jpg',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-unosat/our-portfolio/strengthening-capacities-use-geospatial-information-improved-resilience-asia-pacific-and-africa',
    },
    {
      title: 'Satellite Imagery as Evidence in International Justice Proceedings',
      date: 'DD Month YYYY',
      image:
        'https://unitar.org/sites/default/files/media/image/UNESCOUkrainePressConf_26102022_29.jpg',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-unosat/our-portfolio/satellite-imagery-evidence-international-justice-proceedings',
    },
    {
      title: 'Mapping of Religious Sites',
      date: 'DD Month YYYY',
      image:
        'https://unitar.org/sites/default/files/media/image/cover%20PoA%20Religious%20sites_0.png',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-unosat/our-portfolio/mapping-religious-sites',
    },
    {
      title: 'UN Asign',
      date: 'DD Month YYYY',
      image: 'https://unitar.org/sites/default/files/media/image/crowdviewerscreenshot052021.png',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-unosat/our-portfolio/un-asign',
    },
    {
      title: 'Reach - Informing More Effective Humanitarian Action',
      date: 'DD Month YYYY',
      image: 'https://unitar.org/sites/default/files/media/image/header1.jpg',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-unosat/our-portfolio/reach-informing-more-effective-humanitarian-action',
    },
    {
      title: 'Satellite Imagery to Support Civil & Voter Registries Verification and Validation',
      date: 'DD Month YYYY',
      image:
        'https://unitar.org/sites/default/files/media/image/Vanuatu_ProvincialElections_may21_creditsUNDP_1%281%29.jpg',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-unosat/our-portfolio/satellite-imagery-support-civil-voter-registries-verification-and-validation',
    },
    {
      title: 'Enhanced Monitoring & Evaluation using Geospatial Technologies',
      date: 'DD Month YYYY',
      image: 'https://unitar.org/sites/default/files/media/image/BADEA.png',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-unosat/our-portfolio/enhanced-monitoring-evaluation-using-geospatial-technologies',
    },
    {
      title: 'Development of Guyana’s Nationwide Flood Forecasting System',
      date: 'DD Month YYYY',
      image: 'https://unitar.org/sites/default/files/media/image/Guyana_Woman_0.png',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-unosat/our-portfolio/development-guyanas-nationwide-flood-forecasting-system',
    },
    {
      title: 'Crowdsourcing',
      date: 'DD Month YYYY',
      image: 'https://unitar.org/sites/default/files/media/image/banner_crowdsourcing196.png',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-UNOSAT/our-portfolio/crowdsourcing',
    },
    {
      title: 'Resuau Project',
      date: 'DD Month YYYY',
      image: 'https://unitar.org/sites/default/files/media/image/bannerreseau296.png',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-UNOSAT/our-portfolio/reseau-project',
    },
    {
      title: 'EO4SD Fragility, conflict and security',
      date: 'DD Month YYYY',
      image: 'https://unitar.org/sites/default/files/media/image/Logo_map.png',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-unosat/our-portfolio/eo4sd-fragility-conflict-and-security',
    },
    {
      title: 'CommonSensing: Building Climate Resilience with Small Island Nations',
      date: 'DD Month YYYY',
      image: 'https://unitar.org/sites/default/files/media/image/CommonSensing.png',
      href: 'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-unosat/our-portfolio/commonsensing-building-climate-resilience-small-island-nations',
    },
  ];

  return (
    <MainContainer>
      <HomeHeader />
      <Content>
        <Title>UNOSAT Projects</Title>
        <Description>
          Explore our diverse projects spanning the globe. From emergency response to sustainable
          development, see how UNOSAT's initiatives are fostering resilience and innovation in
          various regions.
        </Description>
        <ProjectList>
          {projects.map((project, index) => (
            <ProjectItem key={index} index={index}>
              <ProjectInfo>
                <ProjectTitle>{project.title}</ProjectTitle>
                <SeeMoreButton>
                  <a href={project.href} target="_blank" rel="noreferrer">
                    See More
                  </a>
                </SeeMoreButton>
              </ProjectInfo>
              <ProjectImage>
                <img src={project.image} alt={project.title} />
              </ProjectImage>
            </ProjectItem>
          ))}
        </ProjectList>
      </Content>
    </MainContainer>
  );
};

export default ProjectNew;
