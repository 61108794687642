import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import MoreMapsItem from './MoreMapsItem';
import screenSizes from '../../../constants/screenSizes';
import ProductDetailsLarge from './ProductDetailsLarge';
import { useParams } from 'react-router-dom';
import ContentWrapper from '../../../components/ContentWrapper';
import HomeHeader from '../../../components/site-header/HomeHeader';

const defaultProducts = {
  map_event: {
    id: 3816,
    title:
      'Preliminary satellite-derived flood assessment, Sava & Sofia Regions, Madagascar - 03 April 2024',
    description:
      'Status: Flooded agricultural areas and potentially affected structures observed\r\nFurther action(s): continue monitoring\r\nPotentially affected structures and flooded areas observed in Vohemar Commune, Vohemar District, Sava Region as of 31 March 2024;\r\nFlooded agricultural area observed in Bealanana Commune, Bealanana District, Sofia Region as of 01 April 2024;\r\nFlooded agricultural area observed in Matsohely & Belaoka Marovato Commmunes, Andapa District, Sava Region as of 31 March 2024;\r\nFlooded agricultural area observed in Antalaha Town, Antalaha District, Sava Region as of 31 March 2024.',
    sources:
      '(1) Satellite Image (Post-event): WorldView-3\r\nAcquisition date : 31 March 2024\r\nResolution: 33cm\r\nCopyright: © 2024 Maxar\r\nSource: USGS\r\nSatellite Image (Post-event): PlanetScope\r\nAcquisition date : 31 March 2024 & 01 April 2024\r\nResolution: 3m\r\nCopyright: © Planet Labs Inc. (2024)\r\nSource: PLANET\r\n(2) ESRI Basemap\r\n(3) Ancillary data\r\nAdministrative boundaries: UNOCHA Field Information Services Section (FISS), HDX\r\nPopulated place: OpenStreetMap\r\nWaterway: OpenStreetMap\r\nAnalysis: United Nations Satellite Centre (UNOSAT)\r\nProduction: United Nations Satellite Centre (UNOSAT)',
    image_file:
      'https://unosat-dev.app.cern.ch/static/unosat_filesystem/3816/UNOSAT_Preliminary_Assessment_Report_TC20240328MDG_Sava_SofiaRegions_03Apr2024.jpg',
    pdf_name: 'UNOSAT_Preliminary_Assessment_Report_TC20240328MDG_Sava_SofiaRegions_03Apr2024.pdf',
    excel_table: '/unosat_filesystem/3816/None',
    glide: 'TC20240328MDG',
    created_at: '2024-04-03T14:34:02.000045Z',
    wms_link: '',
    wmap_link:
      'https://experience.arcgis.com/experience/64da660b449f4324a6127bb4d5aada77/page/UNOSAT/?dlg=Information&draft=true&views=Layers',
    gdp_link: null,
    shp_link: null,
    kml_link: null,
  },
  longitude: 49.230526999999995,
  latitude: -14.03321,
  related_products: [
    {
      id: 3815,
      title: 'UNOSAT Live Web Map - Tropical TEST Gamane-24 Madagascar',
      image_file: '/static/unosat_filesystem/3815/UNOSAT_LiveWebMap_TC20240328MDG.png',
      created_at: '2024-04-03T07:59:37.369931Z',
    },
    {
      id: 3811,
      title:
        'Satellite detected water extent over Maputo province, Mozambique, as of 23 March 2024',
      image_file: '/static/unosat_filesystem/3811/Screenshot%202024-03-27%20142059.png',
      created_at: '2024-03-27T10:38:04.674198Z',
    },
    {
      id: 3809,
      title:
        'Preliminary Satellite-derived Flood Assessment Report, Maputo City, Mozambique, 23 March 2023',
      image_file: '/static/unosat_filesystem/3809/Screenshot%202024-03-26%20105318.png',
      created_at: '2024-03-26T10:00:49.319493Z',
    },
    {
      id: 3808,
      title:
        'Satellite detected water extent over Gaza and Maputo provinces, Mozambique, as of 16 Mar. 2024',
      image_file:
        '/static/unosat_filesystem/3808/UNOSAT_A3_Portrait_TC20240313MOZ_Maputo_Gaza_Mozambique_20240316.png',
      created_at: '2024-03-21T15:28:21.705319Z',
    },
  ],
};

const MoreMapsSection = styled.div`
  @media (min-width: ${screenSizes.medium.min}) {
    padding: 60px 0;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 100px 0;
  }
`;

const MoreMapsTitle = styled.div`
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Text = styled.div`
  padding: 12px 0 12px 0;
  color: rgba(82, 94, 104, 1);
  font-weight: 800;
  font-size: 18px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 12px 10px 20px 0;
    font-size: 20px;
  }
`;

const Divider = styled.div`
  border-bottom: 2px solid rgba(82, 94, 104, 1);
  width: 100%;
`;

const MoreMapsWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media (min-width: ${screenSizes.small.min}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 32px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const ProductDetails = () => {
  const [productData, setProductData] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState(null);
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });

  const params = useParams();

  useEffect(() => {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
    };
    const IS_LOCALHOST = window.location.hostname === 'localhost';
    axios
      .get(`/our_products/${params.id}`, { headers })
      .then((response) => {
        setProductData(response?.data?.map_event);
        setRelatedProducts(response?.data?.related_products);
        setCoordinates({
          latitude: response?.data?.latitude,
          longitude: response?.data?.longitude,
        });
      })
      .catch((error) => {
        console.error('Error fetching product details:', error);
        if (!IS_LOCALHOST) {
          return;
        }
        setProductData(defaultProducts.map_event);
        setRelatedProducts(defaultProducts.related_products);
        setCoordinates({
          latitude: defaultProducts.latitude,
          longitude: defaultProducts.longitude,
        });
      });
  }, [params.id]);

  const [limitOfRelatedProducts, setLimitOfRelatedProducts] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setLimitOfRelatedProducts(1);
      } else if (window.innerWidth <= 1100) {
        setLimitOfRelatedProducts(2);
      } else {
        setLimitOfRelatedProducts(4);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <HomeHeader />
      <ContentWrapper bgColor={'#E4EEF6'}>
        {productData && (
          <ProductDetailsLarge
            productData={productData}
            lat={coordinates.latitude}
            lon={coordinates.longitude}
          />
        )}
        {relatedProducts?.length > 0 && (
          <MoreMapsSection>
            <MoreMapsTitle>
              <Text>MORE PRODUCTS</Text>
              <Divider />
            </MoreMapsTitle>
            <MoreMapsWrapper>
              {relatedProducts.slice(0, limitOfRelatedProducts).map((item, index) => (
                <MoreMapsItem {...item} key={`related-product-${index}`} />
              ))}
            </MoreMapsWrapper>
          </MoreMapsSection>
        )}
      </ContentWrapper>
    </div>
  );
};

export default ProductDetails;
