import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';

const Title = styled.h2`
  font-size: 13px;
  font-weight: 700;
  color: #fff};
  position: relative;
  ${({ hasLeftLine }) => hasLeftLine && `border-left: 3px solid rgba(82, 94, 104, 1)`};
  margin-bottom: 0;
  padding: 10px;
  line-height: 16px;
  font-family: 'Inter', sans-serif;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 28px;
    white-space: nowrap;
    padding: 10px 80px 10px 10px;

    ::after {
      content: attr(data-content);
      position: absolute;
      left: 8px;
      font-size: 32px;
      top: 6px;
      letter-spacing: 1px;
      z-index: -1;
      color: transparent;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }
  }
`;

const Divider = styled.div`
  @media (min-width: ${screenSizes.medium.min}) {
    border-top: 2px solid rgba(82, 94, 104, 1);
    width: 100%;
    margin-left: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ isUnderlined }) => isUnderlined && `border-bottom: 2px solid rgba(82, 94, 104, 1);`};
`;

const SectionTitle = ({ text, hasLine = false, isUnderlined = false, hasLeftLine = true }) => (
  <Wrapper isUnderlined={isUnderlined}>
    <Title data-content={text} hasLeftLine={hasLeftLine}>
      {text}
    </Title>
    {hasLine && <Divider />}
  </Wrapper>
);

export default SectionTitle;
