import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import { parseISO, format as formatDate } from 'date-fns';
import fallbackImage from '../../../assets/map-photo.png';
import Button from '../../../components/common/Button';
import DownloadLinks from './DownloadLinks';
import { colors } from '../../../constants/colors';
import screenSizes from '../../../constants/screenSizes';
import { routes } from '../../../constants/routes';
import { urls } from '../../../constants/urls';

const PageWrapper = styled.div`
  padding: 0 4px;
  @media (min-width: ${screenSizes.large.min}) {
    padding: 0 50px;
  }
`;

const GoBackButton = styled.div`
  cursor: pointer;
  margin-top: 80px;
  border-radius: 20px;
  width: fit-content;
  padding: 6px 12px;
  color: white;
  font-weight: 800;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  background-color: rgba(119, 139, 159, 0.7);

  @media (max-width: ${screenSizes.medium.max}) {
    padding: 6px 10px;
    font-size: 24px;
    background-color: transparent;
    color: rgba(119, 139, 159, 0.7);
    margin-left: -10px;
    margin-top: 60px;
  }

  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 140px;
    font-size: 13px;
  }
`;

const ButtonContent = styled.span`
  @media (max-width: ${screenSizes.medium.max}) {
    display: none;
  }
`;

const ArrowIcon = styled.span`
  display: none;
  font-size: 28px;
  font-weight: 300;
  color: rgba(72, 72, 72, 1);
  @media (max-width: ${screenSizes.medium.max}) {
    display: inline;
  }
`;

const DateUnderButton = styled.div`
  margin-top: 12px;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  color: rgba(82, 94, 104, 1);
  font-weight: 700;
  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 36px;
    margin-left: 4px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (min-width: ${screenSizes.large.min}) {
    flex-direction: row;
    margin-top: 28px;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  @media (min-width: ${screenSizes.large.min}) {
    width: 30%;
    margin-left: 72px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
`;

const TitleWrapper = styled.h3`
  font-size: 25px;
  color: rgba(82, 94, 104, 1);
  margin: 0 0 16px 0;
  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 36px;
    margin: 0 0 24px 0;
  }
`;

const StrongPart = styled.span`
  font-weight: 900;
  margin-right: 5px;
`;

const RestPart = styled.span`
  font-weight: 700;
`;

const Title = ({ title }) => {
  const [strongPart, ...restParts] = title.split(' - ')[0].split(',');
  const restPart = restParts.join(',');

  return (
    <TitleWrapper>
      <StrongPart>{strongPart}</StrongPart>
      {restPart && <RestPart>,{restPart}</RestPart>}
    </TitleWrapper>
  );
};

const MainContent = styled.div`
  font-size: 16px;
  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 18px;
  }
`;

const Description = styled.div`
  width: 100%;
  margin-top: 30px;
  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 60px;
  }
`;

const DescriptionLine = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  font-weight: 600;
  padding-bottom: 12px;
  color: rgba(82, 94, 104, 1);
  border-bottom: 2px solid rgba(82, 94, 104, 1);
  display: flex;
  flex-direction: column;
  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
    line-height: 23px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const BoldLabel = styled.span`
  font-weight: 800;
  margin-bottom: 4px;
  @media (min-width: ${screenSizes.medium.min}) {
    flex: 0 0 22%;
    text-align: left;
    margin-bottom: 0;
  }
`;

const ContentPart = styled.span`
  overflow-wrap: break-word;

  @media (min-width: ${screenSizes.medium.min}) {
    flex: 0 0 78%;
    text-align: left;
  }
`;

const DescriptionContent = ({ description }) => {
  const formatLine = (line) => {
    if (line.includes(':')) {
      const [label, content] = line.split(':');
      return (
        <>
          <BoldLabel>{label}:</BoldLabel>
          <ContentPart>{content}</ContentPart>
        </>
      );
    } else {
      const words = line.split(' ');
      const boldPart = words.slice(0, 3).join(' ');
      const restPart = words.slice(3).join(' ');
      return (
        <>
          <BoldLabel>{boldPart}</BoldLabel>
          <ContentPart>{restPart}</ContentPart>
        </>
      );
    }
  };

  return (
    <Description>
      {description?.split('\n').map((item, index) => (
        <DescriptionLine key={index}>{formatLine(item)}</DescriptionLine>
      ))}
    </Description>
  );
};

const ImageWrapper = styled.div`
  border-radius: 10px;
  margin-top: 20px;
  min-height: 10vh;
  overflow: hidden;
  display: none;
  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 40px;
    height: 50vh;
    display: block;
  }
`;

const ImageWrapperMobile = styled.div`
  border-radius: 10px;
  margin-top: 20px;
  min-height: 10vh;
  overflow: hidden;
  display: block;
  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 40px;
    display: none;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const ProductInfo = styled.div`
  margin-bottom: 24px;
  text-align: left;

  @media (max-width: ${screenSizes.medium.max}) {
    display: none;
  }
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 100%;
  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: row;
    align-items: baseline;
  }
`;

const InfoLabel = styled.span`
  font-weight: 800;
  color: #333;
  margin-bottom: 4px;
  @media (min-width: ${screenSizes.medium.min}) {
    margin-right: 8px;
    flex-shrink: 0;
    min-width: 140px;
    margin-bottom: 0;
  }
`;

const InfoValue = styled.span`
  font-weight: 600;
  text-align: left;
`;

const MobileButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 24px 0;
  margin-top: px;
  @media (min-width: ${screenSizes.medium.min}) {
    display: none;
  }
`;

const DesktopButtonsWrapper = styled.div`
  display: none;

  @media (min-width: ${screenSizes.medium.min}) {
    display: block;
    margin-top: 100px;
  }
`;

const FeedbackItem = styled.div`
  display: flex;
  transition: all 0.3s ease;
  background: rgba(119, 139, 159, 0.7);
  align-items: center;
  border-radius: 20px;
  height: 28px;
  padding: 0 20px;
  width: fit-content;
  text-align: center;
  margin-top: 16px;

  &:hover {
    background: ${colors.BLUE};
  }
`;

const FeedbackText = styled.a`
  font-weight: 800;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
`;

const RightColumnContent = styled.div`
  @media (min-width: ${screenSizes.large.min}) {
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const TopRightContent = styled.div``;

const BottomRightContent = styled.div`
  @media (min-width: ${screenSizes.large.min}) {
    margin-top: auto;
    max-height: 50vh;
    overflow-y: auto;
  }
`;

const Sources = styled.div`
  line-height: 16px;
  padding-left: 15px;
  font-size: 11px;
  font-weight: 700;

  @media (max-width: ${screenSizes.medium.max}) {
    display: none;
  }
`;

const ProductDetailsLarge = ({ productData = {}, lat, lon }) => {
  const history = useHistory();
  const {
    title,
    description,
    sources,
    id,
    created_at,
    glide,
    image_file,
    wmap_link,
    gdp_link,
    shp_link,
    kml_link,
    wms_link,
    pdf_name,
    excel_table,
  } = productData;

  const handleContactUsClick = () => {
    history.push(routes.CONTACT);
  };

  const goBack = () => {
    history.goBack();
  };

  const transformedSources = sources?.split('\n');

  return (
    <PageWrapper>
      <GoBackButton onClick={goBack}>
        <ArrowIcon>&#8249;</ArrowIcon>
        <ButtonContent>BACK TO MAP</ButtonContent>
      </GoBackButton>
      <DateUnderButton>{formatDate(parseISO(created_at), 'dd MMM yyyy')}</DateUnderButton>
      <ContentWrapper>
        <LeftColumn>
          <Title title={title} />
          <ImageWrapperMobile>
            <StyledImage src={image_file || fallbackImage} alt="Product" />
          </ImageWrapperMobile>
          <MainContent>
            <DescriptionContent description={description} />
            <DownloadLinks
              title={title}
              pdfName={pdf_name !== 'None' ? pdf_name : undefined}
              excel={excel_table.includes('/None') ? undefined : excel_table}
              wmap={wmap_link}
              gdp={gdp_link}
              shp={shp_link}
              wms={wms_link}
              kml={kml_link}
              id={id}
            />
            <MobileButtonsWrapper>
              <Button onClick={handleContactUsClick} variant="white">
                CONTACT US
              </Button>
              <FeedbackItem>
                <FeedbackText href={urls.FEEDBACK} target="_blank" rel="noreferrer">
                  PRODUCT FEEDBACK
                </FeedbackText>
              </FeedbackItem>
            </MobileButtonsWrapper>
            <ImageWrapper>
              <StyledImage src={image_file || fallbackImage} alt="Product" />
            </ImageWrapper>
          </MainContent>
        </LeftColumn>
        <RightColumn>
          <RightColumnContent>
            <TopRightContent>
              <ProductInfo>
                <InfoItem>
                  <InfoLabel>PRODUCT ID:</InfoLabel>
                  <InfoValue>{id}</InfoValue>
                </InfoItem>
                <InfoItem>
                  <InfoLabel>PUBLISHED:</InfoLabel>
                  <InfoValue>{formatDate(parseISO(created_at), 'dd MMM yyyy')}</InfoValue>
                </InfoItem>
                {glide && (
                  <InfoItem>
                    <InfoLabel>GLIDE:</InfoLabel>
                    <InfoValue>{glide}</InfoValue>
                  </InfoItem>
                )}
              </ProductInfo>
              <DesktopButtonsWrapper>
                <Button onClick={handleContactUsClick} variant="white">
                  CONTACT US
                </Button>
                <FeedbackItem>
                  <FeedbackText href={urls.FEEDBACK} target="_blank" rel="noreferrer">
                    PRODUCT FEEDBACK
                  </FeedbackText>
                </FeedbackItem>
              </DesktopButtonsWrapper>
            </TopRightContent>
            <BottomRightContent>
              {transformedSources && (
                <Sources>
                  {transformedSources.map((item, index) => (
                    <div key={`item-${index}`}>{item}</div>
                  ))}
                </Sources>
              )}
            </BottomRightContent>
          </RightColumnContent>
        </RightColumn>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ProductDetailsLarge;
