import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { ArrowCircleUp } from 'phosphor-react';

const Button = styled.button`
  position: fixed;
  bottom: 120px;
  right: 20px;
  padding: 10px;
  background: rgba(119, 139, 159, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s, background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
    background-color: rgba(119, 139, 159, 1);
  }

  @media (max-width: 768px) {
    bottom: 20px;
    right: 20px;
    display: none;
  }
`;

const IconWrapper = styled.div`
  color: white;
  transition: color 0.3s;

  ${Button}:hover & {
    color: #ffffff;
  }
`;

const ButtonToTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Button onClick={scrollToTop}>
      <IconWrapper>
        <ArrowCircleUp size={52} weight="fill" />
      </IconWrapper>
    </Button>
  );
};

export default ButtonToTop;
